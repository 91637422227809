import GraphqlService from "../GraphqlService";
import {
  type PatientSchema,
  type AppointmentSchema, appointmentSchemaAllFields,
  type TaskSchema,
  type LaboratoryWorkSchema, laboratoryWorkSchemaAllFields,
  type PrescriptionSchema,
  type PrescriptionTemplateSchema,
  type DocumentSchema,
  type DocumentTemplateSchema,
  type PatientFamilySchema,
  type IncomeSchema,
  type PatientFileSchema, patientFileSchemaAllFields,
  type MedicalFormSchema, medicalFormSchemaAllFields,
  type MedicalFormTemplateSchema,
  type MedicalRecordSchema
} from "@/models/schemas";
import type {
  SavePatientInput, UpdatePatientInput,
  SavePatientPrescriptionInput,
  SavePatientDocumentInput,
  SavePatientFamilyInput,
  SavePatientMedicalFormInput,
  SendPatientFormMessageInput
} from "@/models/api/mutations/PatientModels";
import type { SaveMedicalRecordInput } from "@/models/api/mutations/MedicalRecordModels";
import type { FilterPatientInput } from "@/models/api/queries/PatientModels";
import type { TabulatorParams } from "@/models/interfaces";

const fieldsPatient = `
  id
  createdAt
  updatedAt
  deletedAt

  active
  birthDate
  firstName
  lastName
  surName
  photo
  gender
  familyStatus
  workingPlace
  passportNumber
  addressStreet
  addressHouseNumber
  addressAppartments
  addressCity
  addressZip
  addressCountry
  phone1
  phone2
  phone3
  fax
  email1
  email2
  doNotSendSms
  doNotSendEmails
  doNotCall
  defaultDoctorId
  pricelistId
  balance
  insuranceCompanyId
  insurancePolicyNumber
  insuranceValidationDate
  insuranceEmployeeNumber
  insuranceConditions
  patientType
  appointmentNote
  fileNumber
  sensitivityToMedications
  appointmentColor
  referredBy
  externalId
  denture

  fullName
  defaultDoctorName
  defaultDoctorImage
  lastAppointmentTime
  nextAppointmentTime
`;

const fieldsPrescription = `
  id
  createdAt
  updatedAt
  deletedAt

  patientId
  userId
  templateId
  systemName
  drugName
  submission
  dose
  timesADay
  numberOfDays
  note

  doctorName
  doctorImage
`;

const fieldsPrescriptionTemplate = `
  id
  createdAt
  updatedAt
  deletedAt

  systemName
  drugName
  submission
  dose
  timesADay
  numberOfDays
  note
  instruction
`;

const fieldsDocument = `
  id
  createdAt
  updatedAt
  deletedAt

  userId
  patientId
  title
  text

  doctorName
  doctorImage
`;

const fieldsDocumentTemplate = `
  id
  createdAt
  updatedAt
  deletedAt

  active

  title
  text
`;

const fieldsPatientFamily = `
  id
  createdAt
  updatedAt
  deletedAt

  patientId

  type
  familyId
  note

  patientName
  familyFirstName
  familyLastName
`;

const fieldsMedicalFormTemplate = `
  id
  createdAt
  updatedAt
  deletedAt

  title
  titleShort
  lang

  inputs
`;

const fieldsMedicalRecord = `
  id
  createdAt
  updatedAt
  deletedAt

  patientId
  doctorId
  type
  description
  doctorName
`;

export default class PatientService {
  //#region Patients
  async getPatients(params: TabulatorParams, input?: FilterPatientInput) {
    return await GraphqlService.getItems<PatientSchema>("patients", fieldsPatient, params, {
      variables: [{ field: "input", value: input, valueType: "FilterPatientInput" }]
    });
  }

  async getPatientsAll(fields?: string[] | null, input?: FilterPatientInput) {
    const { data } = await GraphqlService.queryGql<PatientSchema[]>({
      method: "patientsAll",
      fields: fields ?? ["id", "fullName", "photo"],
      variables: [{ field: "input", value: input, valueType: "FilterPatientInput" }],
      order: [
        { field: "firstName", value: "ASC" },
        { field: "lastName", value: "ASC" }
      ]
    });
    return data ?? [];
  }

  async getPatient(id: number, fields?: string[]) {
    return await GraphqlService.getItem<PatientSchema>("patient", fields ?? fieldsPatient, id);
  }

  async getPatientNewCaseNumber() {
    const { data } = await GraphqlService.queryGql<string>({ method: "patientNewCaseNumber" });
    return data;
  }

  async savePatient(input: SavePatientInput) {
    return await GraphqlService.setItem<PatientSchema>("savePatient", "patient", fieldsPatient, input);
  }

  async updatePatient(input: UpdatePatientInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "updatePatient",
      variables: [{ field: "input", value: input, valueType: "UpdatePatientInput" }],
    });
    return data ?? false;
  }

  async deactivatePatient(id: number, active?: boolean) {
    return await GraphqlService.deleteItem("deactivatePatient", id, {
      variables: [{ field: "active", value: active ?? null, valueType: "Boolean" }]
    });
  }

  async checkPatientName(id: number, firstName?: string, lastName?: string, surName?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkPatientName",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "firstName", value: firstName, valueType: "String" },
        { field: "lastName", value: lastName, valueType: "String" },
        { field: "surName", value: surName, valueType: "String" }
      ]
    });
    return data ?? 0;
  }
  async checkPatientPassportNumber(id: number, passportNumber?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkPatientPassportNumber",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "passportNumber", value: passportNumber, valueType: "String" }
      ]
    });
    return data ?? 0;
  }
  async checkPatientPhone(id: number, phone?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkPatientPhone",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "phone", value: phone, valueType: "String" }
      ]
    });
    return data ?? 0;
  }
  async checkPatientEmail(id: number, email?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkPatientEmail",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "email", value: email, valueType: "String" }
      ]
    });
    return data ?? 0;
  }
  async checkPatientFileNumber(id: number, fileNumber?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkPatientFileNumber",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "fileNumber", value: fileNumber, valueType: "String" }
      ]
    });
    return data ?? 0;
  }
  //#endregion

  //#region Appointments
  async getPatientAppointments(id: number, params: TabulatorParams, next?: boolean | null, fields?: string[], includeDeleted?: boolean) {
    if (typeof next === 'boolean') {
      params.sort = [{ field: "startTime", dir: next ? "asc" : "desc" }];
    }
    return await GraphqlService.getItems<AppointmentSchema>("patientAppointments", fields ?? appointmentSchemaAllFields, params, {
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "next", value: next, valueType: "Boolean" },
        { field: "includeDeleted", value: includeDeleted, valueType: "Boolean" },
      ]
    });
  }
  //#endregion

  //#region Tasks
  async getPatientTasks(id: number, params: TabulatorParams, fields: string[]) {
    return await GraphqlService.getItems<TaskSchema>("patientTasks", fields, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region LaboratoryWorks
  async getPatientLaboratoryWorks(id: number, params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<LaboratoryWorkSchema>("patientLaboratoryWorks", fields ?? laboratoryWorkSchemaAllFields, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region Prescriptions
  async getPatientPrescriptions(id: number, params: TabulatorParams) {
    return await GraphqlService.getItems<PrescriptionSchema>("patientPrescriptions", fieldsPrescription, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getPatientPrescriptionTemplatesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PrescriptionTemplateSchema[]>({
      method: "patientPrescriptionTemplatesAll",
      fields: fields ?? fieldsPrescriptionTemplate,
      order: [{ field: "systemName", value: "ASC" }]
    })
    return data;
  }

  async savePatientPrescription(input: SavePatientPrescriptionInput) {
    return await GraphqlService.setItem<PatientSchema>("savePatientPrescription", "prescription", fieldsPrescription, input);
  }

  async deletePatientPrescription(prescriptionId: number) {
    return await GraphqlService.deleteItem("deletePatientPrescription", prescriptionId);
  }
  //#endregion

  //#region Documents
  async getPatientDocuments(id: number, params: TabulatorParams) {
    return await GraphqlService.getItems<DocumentSchema>("patientDocuments", fieldsDocument, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getPatientDocument(documentId: number) {
    return await GraphqlService.getItem<DocumentSchema>("patientDocument", fieldsDocument, documentId);
  }

  async getPatientDocumentTemplatesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<DocumentTemplateSchema[]>({
      method: "patientDocumentTemplatesAll",
      fields: fields ?? fieldsDocumentTemplate,
      order: [{ field: "title", value: "ASC" }]
    })
    return data;
  }

  async savePatientDocument(input: SavePatientDocumentInput) {
    return await GraphqlService.setItem<PatientSchema>("savePatientDocument", "document", fieldsDocument, input);
  }

  async deletePatientDocument(documentId: number) {
    return await GraphqlService.deleteItem("deletePatientDocument", documentId);
  }
  //#endregion

  //#region Family
  async getPatientFamilies(patientId: number, params: TabulatorParams) {
    return await GraphqlService.getItems<PatientFamilySchema>("patientFamilies", fieldsPatientFamily, params, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }]
    });
  }

  async getPatientsForFamilyAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PatientSchema[]>({
      method: "patientsForFamilyAll",
      fields: fields ?? ["id", "fullName", "photo"],
      order: [
        { field: "firstName", value: "ASC" },
        { field: "lastName", value: "ASC" }
      ]
    });
    return data;
  }

  async getPatientFamily(patientFamilyId: number) {
    return await GraphqlService.getItem<PatientFamilySchema>("patientFamily", fieldsPatientFamily, patientFamilyId);
  }

  async savePatientFamily(input: SavePatientFamilyInput) {
    return await GraphqlService.setItem<PatientFamilySchema>("savePatientFamily", "patientFamily", fieldsPatientFamily, input);
  }

  async deletePatientFamily(patientFamilyId: number, patientId: number) {
    return await GraphqlService.deleteItem("deletePatientFamily", patientFamilyId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region Payments
  async getPatientPayments(patientId: number, params: TabulatorParams, fields: string[]) {
    return await GraphqlService.getItems<IncomeSchema>("patientPayments", fields, params, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region Files
  async createPatientFilesDirectory(patientId: number, relativeName: string) {
    await GraphqlService.mutateGql<boolean>({
      method: "createPatientFilesDirectory",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "relativeName", value: relativeName, valueType: "String!" },
      ]
    });
  }

  async movePatientFilesDirectory(patientId: number, relativeName: string, relativeNameNew: string) {
    await GraphqlService.mutateGql<boolean>({
      method: "movePatientFilesDirectory",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "relativeName", value: relativeName, valueType: "String!" },
        { field: "relativeNameNew", value: relativeNameNew, valueType: "String!" },
      ]
    });
  }

  async deletePatientFilesDirectory(patientId: number, relativeName: string) {
    await GraphqlService.mutateGql<boolean>({
      method: "deletePatientFilesDirectory",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "relativeName", value: relativeName, valueType: "String!" },
      ]
    });
  }

  async getPatientFilesAll(patientId: number, search: string | null, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PatientFileSchema[]>({
      method: "patientFilesAll",
      fields: fields ?? patientFileSchemaAllFields,
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "search", value: search, valueType: "String" },
      ],
      order: [
        { field: "isDirectory", value: "DESC" },
        { field: "relativePath", value: "ASC" }
      ]
    })
    return data ?? [];
  }

  async getPatientScanFilesAll(patientId: number, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PatientFileSchema[]>({
      method: "patientScanFilesAll",
      fields: fields ?? patientFileSchemaAllFields,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
      order: [{ field: "updatedAt", value: "DESC" }]
    })
    return data ?? [];
  }

  async movePatientFile(patientId: number, relativeName: string, relativeNameNew: string) {
    await GraphqlService.mutateGql<boolean>({
      method: "movePatientFile",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "relativeName", value: relativeName, valueType: "String!" },
        { field: "relativeNameNew", value: relativeNameNew, valueType: "String!" },
      ]
    });
  }

  async deletePatientFile(patientId: number, relativeName: string) {
    await GraphqlService.mutateGql<boolean>({
      method: "deletePatientFile",
      variables: [
        { field: "id", value: patientId, valueType: "ID!" },
        { field: "relativeName", value: relativeName, valueType: "String!" },
      ]
    });
  }
  //#endregion

  //#region Medical forms
  async getPatientMedicalForms(patientId: number, params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<MedicalFormSchema>("patientMedicalForms", fields ?? medicalFormSchemaAllFields, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
    });
  }

  async getPatientMedicalFormTemplatesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<MedicalFormTemplateSchema[]>({
      method: "patientMedicalFormTemplatesAll",
      fields: fields ?? fieldsMedicalFormTemplate,
      order: [{ field: "id", value: "ASC" }]
    })
    if (data) {
      data.forEach(x => {
        x.inputs = x.inputs?.map(x => Object.assign({}, x)) ?? []; // fix readonly input value
      });
    }
    return data ?? [];
  }

  async savePatientMedicalForm(input: SavePatientMedicalFormInput) {
    return await GraphqlService.setItem<MedicalFormSchema>("savePatientMedicalForm", "medicalForm", medicalFormSchemaAllFields, input);
  }

  async deletePatientMedicalForm(medicalFormId: number, patientId: number) {
    return await GraphqlService.deleteItem("deletePatientMedicalForm", medicalFormId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }

  async sendPatientMedicalFormMessage(input: SendPatientFormMessageInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendPatientMedicalFormMessage",
      variables: [{ field: "input", value: input, valueType: "SendPatientFormMessageInput!" }]
    });
    return data ?? false;
  }
  //#endregion

  //#region Medical records
  async getPatientMedicalRecords(patientId: number, params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<MedicalRecordSchema>("patientMedicalRecords", fields ?? fieldsMedicalRecord, params, {
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
    });
  }

  async getPatientMedicalRecordsAll(patientId: number, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<MedicalRecordSchema[]>({
      method: "patientMedicalRecordsAll",
      fields: fields ?? fieldsMedicalRecord,
      variables: [{ field: "id", value: patientId, valueType: "ID!" }],
      order: [{ field: "id", value: "DESC" }],
    })
    return data ?? [];
  }

  async getPatientMedicalRecord(medicalRecordId: number, patientId: number) {
    return await GraphqlService.getItem<MedicalRecordSchema>("patientMedicalRecord", fieldsMedicalRecord, medicalRecordId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }

  async deletePatientMedicalRecord(medicalRecordId: number, patientId: number) {
    return await GraphqlService.deleteItem("deletePatientMedicalRecord", medicalRecordId, {
      variables: [{ field: "patientId", value: patientId, valueType: "ID!" }],
    });
  }

  async savePatientMedicalRecord(input: SaveMedicalRecordInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "savePatientMedicalRecord",
      variables: [{ field: "input", value: input, valueType: "SaveMedicalRecordInput" }],
    });
    return data ?? false;
  }
  //#endregion
}

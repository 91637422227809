export default {
  databases: { text: "Databases" },
  dashboard: { text: "Dashboard" },
  calendar: { text: "Calendar" },
  patients: { text: "Patients" },
  chat: { text: "Chat" },
  email: { text: "Email" },
  financial: {
    text: "Financial", // *
    items: {
      incomes: { text: "Incomes" },
      expenses: {
        text: "Expenses",
        items: {
          expenses: { text: "List of expenses" },
          suppliers: { text: "Suppliers" },
          types: { text: "Expense types" },
        }
      },
      deposit: {
        text: "Deposit", // *
        items: {
          cash: { text: "Deposit cash" },
          check: { text: "Deposit checks" },
        }
      },
      forecast: { text: "Forecast" },
      reports: {
        text: "Reports", // *
        items: {
          overview: { text: "Overview report" },
          collection: { text: "Collection report" },
          income: { text: "Income report" },
          expenses: { text: "Expenses report" },
          profitAndLoss: { text: "Profit and loss report" },
          vat: { text: "VAT report" },
          advanceTax: { text: "Advance tax report" },
          accounting: { text: "Accounting report between businesses" },
        }
      },
    }
  },
  recalls: { text: "Recalls" },
  leads: { text: "Leads" },
  tasks: { text: "Tasks" },
  laboratoryWorks: { text: "Laboratory works" },
  attendance: { text: "Attendance" },
  settings: {
    text: "Settings", // *
    items: {
      business: { text: "Business settings" },
      users: { text: "Users" },
      services: { text: "Services" },
      priceList: { text: "Price list" },
      documentTemplates: { text: "Document templates" },
      messageTemplates: { text: "Message templates" },
      prescriptionTemplates: { text: "Prescription templates" },
    }
  },
}

export default {
  appointmentStatus: {
    NONE: "None",
    NEW: "חדש",
    CANCELED: "ביטול",
    REMINDER_SENT: "תזכורת נשלחה",
    APPROVED: "אושר",
    TOOK_PLACE: "הגיע",
    DID_NOT_COME: "לא הגיע",
    deleted: "נמחק",
  },
  taskStatus: {
    NONE: "None",
    NEW: "חדש",
    CLOSED: "בוצע",
  },
  incomeType: {
    NONE: "None",
    ESTIMATE: "הצעת מחיר",
    PROFORMA_INVOICE: "חשבון עסקה",
    INVOICE: "חשבונית מס",
    INVOICE_RECEIPT: "חשבונית מס קבלה",
    RECEIPT: "קבלה",
    INVOICE_REFOUND: "חשבונית זיכוי",
    RECEIPT_REFOUND: "קבלה זיכוי"
  },
  incomeStatus: {
    NONE: "None",
    OPEN: "פתוח",
    CLOSED: "סגור",
    INVOICED: "נוצרה חשבונית",
    MANUALLY_CLOSED: "מסמך סומן ידנית כסגור"
  },
  medicalFormStatus: {
    NONE: "None",
    SENT: "נשלח",
    VIEWED: "נצפה",
    FILLED_UP: "חתום",
  },
  receiptPaymentType: {
    NONE: "None",
    CHEQUE: "צ'ק",
    BANK_TRANSFER: "העברה בנקאית",
    CREDIT_CARD: "כרטיס אשראי",
    CASH: "מזומן",
    WITH_HOLDING_TAX: "ניכוי מס במקור"
  },
  creditDealType: {
    NONE: "None",
    REGULAR: "רגיל",
    PAYMENTS: "תשלומים",
    CREDIT: "קרדיט",
    CHARGE_DECLINED: "חיוב נדחה",
    OTHER: "אחר"
  },
  creditBrand: {
    NONE: "None",
    ISRACARD: "ישראכרט",
    VISA: "ויזה",
    MASTER_CARD: "מאסטרקארד",
    AMERICAN_EXPRESS: "אמריקן אקספרס",
    DINERS: "דיינרס"
  },
  treatmentStageStatus: {
    NONE: "None",
    ESTIMATE: "הצעת מחיר",
    ACTIVE: "פעיל",
    FINISHED: "גמור"
  },
  frequency: {
    NONE: "None",
    ONE_TIME: "חד פעמית",
    WEEKLY: "כל שבוע",
    BI_WEEKLY: "כל שבועיים",
    MONTHLY: "כל חודש",
    BI_MONTHLY: "כל חודשיים"
  },
  dayOfWeek: {
    SUNDAY: "יום א'",
    MONDAY: "יום ב'",
    TUESDAY: "יום ג'",
    WEDNESDAY: "יום ד'",
    THURSDAY: "יום ה'",
    FRIDAY: "יום ו'",
    SATURDAY: "יום ש'"
  },
  evenOrOdd: {
    NONE: "None",
    EVEN: "זוגי",
    ODD: "אי זוגי"
  },
  historyAction: {
    NONE: "None",
    CREATE: "הוסף",
    UPDATE: "עדכן",
    DELETE: "למחוק",
  },
  vatType: {
    NONE: "None",
    NORMAL: "רגיל",
    NO_VAT: "ללא מע״מ (אילת וחו״ל)"
  },
  vatBeforeOrInclude: {
    NONE: "None",
    BEFORE_VAT: "לפני",
    INCLUDE_VAT: "כולל"
  },
  messageProvider: {
    NONE: "None",
    WHATSAPP: "ווטסאפ",
    SMS: "SMS",
    EMAIL: "דוא\"ל"
  },
  messageTemplateType: {
    NONE: "None",
    GENERAL: "כללי",
    PATIENT_APPOINTMENT_CREATED: "מטופל - תור נוסף",
    PATIENT_APPOINTMENT_REMINDER: "מטופל - תזכורת לגבי תור (הודעת ברירת מחדל אם תבנית של טפול ריקה)",
    PATIENT_APPOINTMENT_CANCELED: "מטופל - תור בוטל",
    SECRETARY_NEW_LEAD: "ליד חדש נוסף (לא בשימוש)",
    ACCOUNTANT_INCOMES_REPORT: "מנהל חשבונות - דוח הכנסות חדש",
    ACCOUNTANT_EXPENSES_REPORT: "מנהל חשבונות - דוח הוצאות חדש",
    LABORATORY_NEW_LABORATORY_WORK: "עבודת מעבדה חדשה (לא בשימוש)",
    NEW_TASK: "משימה חדשה (לא בשימוש)",
    SEND_MEDICAL_FORM_TEMPLATE: "הודעה בעת שליחת טופס רפואי",
    SEND_APPOINTMENT_LINK_TEMPLATE: "הודעה בעת שליחת קישור לזימון תור",
    SEND_FINANCIAL_DOCUMENT: "שליחת מסמך חשבונאי",
    NOTES_IN_FOLLOW_UP: "הודעת מעקב רפואי",
    SIGN_MEDICAL_FORM_REMINDER: "הודעת תזכורת לגבי טופס רפואי שאינו נחתם (24 שעות)",
    SEND_TREATMENT_PLAN_TEMPLATE: "תוכן ההודעה בעת שליחת תוכנית הטיפול"
  }
}

export default {
  head: {
    title: "Chat",
    meta: [
      { name: "description", content: "Chat description" }
    ]
  },
  addNewPatient: "Add a new patient",
  chatSettings: {
    title: "Chat settings",
    tooltip: "WhatsApp and SMS settings",
    options: {
      whatsapp: "WhatsApp settings",
      sms: "SMS settings"
    }
  },
  clearChat: "Clear chat",
  clearThisChat: "Clear this chat?",
  clientNotRegistered: "Client {0} is not registered in the system",
  clientSearch: "Search...",
  copy: "Copy",
  enterMessage: "Enter message",
  fileSavedInPatientFolder: "File saved in patient folder",
  fileWasNotSavedInPatientFolder: "File was not saved in patient folder",
  messageSent: "Message sent",
  muteNotifications: "Mute notifications",
  noSearchResultsFound: "No search results found",
  saveInPatientFolder: "Save in patient folder",
  search: "Search for messages with {0}.",
  sendingSms: "(Sending sms)",
  sendingWhatsapp: "(Sending whatsapp)",
  smsMessage: "SMS message",
  unmuteNotifications: "Unmute notifications",
  whatsappMessage: "WhatsApp message",
  noActiveWhatsapp: "No active whatsapp",
  noActiveSms: "No active Sms",
}

export default {
  title: "תשלומים",
  buttons: {
    add: "הפקת מסמכים",
    addCopy: "שכפול",
    addRelated: "הפקת מסמך חדש מתוך מסמך זה",
    addCardPayment: "הוספת תשלום באשראי",
    send: "שליחת מסמך",
    view: "צפיה",
    reopen: "פתיחה מחודשת של המסמך",
    download: "הורדת מסמך"
  },
  form: {
    title: "הוספת מסמך הכנסות",
    documentType: "סוג מסמך",
    connect: "בחרו טיפולים",
    submit: "הפק מסמך",
    typeError: "סוג הכנסה לא נתמך",
    receiptError: "סוג הקבלה אינו תומך בשורות החשבונית",
    total: "סכום",
    treatmentPlans: {
      title: "תוכניות טיפול",
      table: {
        columns: {
          createdAt: "תאריך יצירה",
          status: "סטטוס",
          total: "סכום",
          due: "יתרה"
        }
      }
    },
    treatments: {
      title: "טיפולים",
      table: {
        columns: {
          createdAt: "תאריך יצירה",
          description: "טיפול",
          statusTitle: "סטטוס",
          due: "יתרה"
        }
      }
    }
  },
  formCard: {
    title: "הוספת תשלום באשראי",
    subTotal: 'סה"כ',
    tax: 'מע"מ',
    total: 'סה"כ לתשלום',
    submit: "המשך לתשלום",
    payment: {
      amount: "סכום לתשלום",
      vatType: "סוג המע״מ",
      vat: 'מע"מ',
      reason: "סיבת תשלום",
      method: "תשלום דרך",
      terminal: "מכשיר סליקה",
      form: "סליקה ידנית",
      link: {
        btn: "שליחת קישור",
        select: "איך לשלוח לינק לתשלום",
        options: {
          email: 'דוא"ל',
          whatsapp: "Whatsapp",
          sms: "סמס",
        },
        input: "למי לשלוח לינק לתשלום",
      },
      submit: "המשך לתשלום",
    }
  },
  table: {
    columns: {
      id: "#",
      incomeStatus: "סטטוס מסמך",
      financialNumber: "#",
      documentDate: "תאריך יצירה",
      patientName: "עבור",
      doctorName: "רופא",
      incomeType: "סוג",
      description: "תיאור",
      payAmount: "סכום",
      paid: "שולם",
      treatmentIds: "הטיפולים",
      treatmentPlanIds: "תוכניות טיפול",
    }
  }
}

export default {
  title: "ממצאים",
  table: {
    columns: {
      id: "ID",
      createdAt: "תאריך",
      doctorName: "רופא",
      description: "תיאור"
    }
  }
}

export default {
  head: {
    title: "דיווח שעות",
    meta: [
      { name: "description", content: "דיווח שעות" }
    ]
  },
  automaticTime: "זמן אוטומטי",
  addAttendance: "הוספה",
  addAttendanceFormHeader: "הוסף דיווח שעות",
  updateAttendance: "עדכון זמן עבודה",
  chooseUser: "בחר משתמש",
  manualTime: "זמן ידני",
  member: "איש צוות",
  fromHour: "משעה",
  toHour: "עד שעה",
  note: "הערה",
  fromDate: "מתאריך",
  date: "תאריך",
  toDate: "עד תאריך",
  deletePopupText: "האם אתה בטוח שברצונך למחוק נוכחות זו",
  fromDateValidationMessage: "התאריך מ חייב להיות מוקדם יותר מהתאריך ל",
  toDateValidationMessage: "התאריך ל חייב להיות אחרי התאריך מ",
  attendanceReport: "דוח נוכחות",
  reportDownload: "הורדת הדוח",
  totalWorked: "סך הכל עבד",
  "title": "דיווח שעות",
  "continue": "הוספת זמן עבודה",
  "delete_attendance": "מחק זמן עבודה",
}

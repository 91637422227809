import type { UserPermissionsSchema } from "@/models/schemas";
import type { UpdateUserPermissionsInput } from "@/models/api/mutations/UserModels";
import GraphqlService from "../GraphqlService";

const fields = `
  userId
  createdAt
  updatedAt

  showPatient
  showCalendars
  showCalendarIds

  financial
  financialReports
  incomes
  expenses
  forecast
  deposit
  accountingReport

  appointments
  leads
  laboratoryWorks
  tasks
  attendance
  settings

  emails
  recall
  chat
  users
  patients
`;

export default class UserPermissionsService {
  async getMyPermissions() {
    const { data } = await GraphqlService.queryGql<UserPermissionsSchema>({ method: "myPermissions", fields: fields })
    return data;
  }

  async getUserPermissions(id: number) {
    return await GraphqlService.getItem<UserPermissionsSchema>("userPermissions", fields, id);
  }

  async updateUserPermissions(input: UpdateUserPermissionsInput) {
    return await GraphqlService.setItem<UserPermissionsSchema>("updateUserPermissions", "userPermissions", fields, input);
  }
}

import navbar from "./navbar";
import menuitems from "./menuitems";
import medicalRecord from "./views/medical-records/medical-record";

import primeDatepicker from "./components/primeDatepicker";
import primeSignaturePad from "./components/primeSignaturePad";

import enums from "./utils/enums";

import page404 from "./views/pages/404";
import page500 from "./views/pages/500";

import databases from "./views/databases";

import accountLogin from "./views/account/login";
import accountPasswordReset from "./views/account/passwordReset";
import accountPasswordChange from "./views/account/passwordChange";
import attendance from "./views/attendance/attendance";

import calendar from "./views/calendar/calendar";
import calendarAppointment from "./views/calendar/appointment";
import calendarWaitlist from "./views/calendar/waitlist";
import calendarReminder from "./views/calendar/appointmentReminder";

import chat from "./views/chat/index";
import chatSettings from "./views/chat/settings";

import dashboard from "./views/dashboard";
import depositCash from "./views/deposit/cash";
import depositChecks from "./views/deposit/checks";

import forecastList from "./views/forecast";

import income from "./views/incomes/income";
import incomesList from "./views/incomes/list";
import incomesPreview from "./views/incomes/preview";
import incomeSaveBeforePopup from "./views/incomes/popups/save-before-popup";
import incomeSaveAfterPopup from "./views/incomes/popups/save-after-popup";
import incomePreviewPopup from "./views/incomes/popups/preview-popup";
import incomeAddDocumentPopup from "./views/incomes/popups/add-document-popup";
import incomeSendPopup from "./views/incomes/popups/send-popup";
import incomeRelatedDocsPopup from "./views/incomes/popups/related-docs-popup";

import email from "./views/email/email"

import expensesList from "./views/expenses/expenses";
import expenseSuppliers from "./views/expenses/suppliers";
import expenseTypes from "./views/expenses/types";

import laboratoryWork from "./views/lab-works/lab-work";
import laboratoryWorksList from "./views/lab-works/list";
import laboratoryWorkLaboratories from "./views/lab-works/laboratories";
import laboratoryWorkLaboratory from "./views/lab-works/laboratory";

import lead from "./views/leads/lead";
import leadList from "./views/leads/list";
import leadChannels from "./views/leads/channels";
import leadChannel from "./views/leads/channel";

import patient from "./views/patients/patient";
import patientsList from "./views/patients/list";
import patientPrescriptions from "./views/patients/prescriptions";
import patientDocuments from "./views/patients/documents";
import patientMedicalForms from "./views/patients/medical-forms";
import patientMedicalRecords from "./views/patients/medical-records";
import patientFiles from "./views/patients/files";
import patientTasks from "./views/patients/tasks";
import patientTreatments from "./views/patients/treatments";
import patientTreatmentPlans from "./views/patients/treatment-plans";
import patientLaboratoryWorks from "./views/patients/laboratory-works";
import patientFamily from "./views/patients/family";
import patientPayments from "./views/patients/payments";

import treatment from "./views/patients/treatments/treatment";

import recall from "./views/recall/recall"

import reportsAccounting from "./views/reports/accounting";
import reportsAdvanceTax from "./views/reports/advance-tax";
import reportsCollection from "./views/reports/collection";
import reportsExpenses from "./views/reports/expenses";
import reportsIncome from "./views/reports/income";
import reportsOverview from "./views/reports/overview";
import reportsProfitAndLoss from "./views/reports/profit-and-loss";
import reportsVat from "./views/reports/vat";

import settingsBusiness from "./views/settings/business";
import settingsPricelist from "./views/settings/pricelist/pricelist";
import settingsPricelistGroups from "./views/settings/pricelist/groups";
import settingsPricelistGroup from "./views/settings/pricelist/group";
import settingsUsers from "./views/settings/users/users";
import settingsUser from "./views/settings/users/user";
import settingsServices from "./views/settings/services/services";
import settingsService from "./views/settings/services/service";
import settingsServiceCategories from "./views/settings/services/categories";
import settingsServiceCategory from "./views/settings/services/category";
import settingsTemplatesDocuments from "./views/settings/templates/documents";
import settingsTemplatesDocument from "./views/settings/templates/document";
import settingsTemplatesMessages from "./views/settings/templates/messages";
import settingsTemplatesMessage from "./views/settings/templates/message";
import settingsTemplatesPrescriptions from "./views/settings/templates/prescriptions";
import settingsTemplatesPrescription from "./views/settings/templates/prescription";

import task from "./views/tasks/task";
import tasksList from "./views/tasks/list";
import externalCalendars from "./views/settings/externalCalendars/externalCalendars";

export default {
  "default": {
    yes: "Yes",
    no: "No",
    create: "Create",
    update: "Update",
    delete: "Delete",
    cancel: "Cancel",
    cancelAction: "Cancel",
    error: "Error",
    edit: "Edit",
    save: "Save",
    saved: "Data saved successfully!",
    reallyRemove: "Should I delete {0}?",
    notFound: "No results found",
    send: "Send",
    sended: "Sended",
    all: "All",
    new: "New",
    clear: "Clear",
    ok: "OK",
    select: "Select",
    selectAll: "Select all",
    selectOption: "Select option",
    search: "Search",
    filters: "Filters",
    close: "Close",
    columnSelection: "Column selection",
    actions: "Actions",
    connect: "Connect",
    disconnect: "Disconnect",
    fileDownloadMessage: "File will download in a few seconds",
    unloadMessage: "Leave without saving?",
    addVariable: "Add variable",
    title: "Title",
    validation: {
      selectDateRange: "Please select start and end dates",
      dateRange: "[From] can't be greater than [To], please select a valid date range",
      checkEmails: "Please enter a valid email"
    },
    systemVariables: {
      AppointmentDayAndDateTime: "Appointment day and date with time",
      AppointmentDateAndHour: "Appointment date and hour",
      AppointmentDate: "Appointment date",
      AppointmentHour: "Appointment hour",
      AppointmentWeekDay: "Appointment week day",
      AppointmentNote: "Appointment note",
      AppointmentDuration: "Appointment duration",
      DoctorName: "Doctor name",
      PatientFullName: "Patient full name",
      PatientFirstName: "Patient first name",
      PatientLastName: "Patient last name",
      CompanyName: "Company name",
      CompanyAddress: "Company address",
      CompanyPhoneNumber: "Company phone number",
      PrescriptionInstruction: "Prescription instruction",
      ServiceName: "Service name",
    }
  },
  "t-menu": "Menu",
  chat: chat,
  chatSettings: chatSettings,
  navbar,
  menuitems,
  components: {
    primeDatepicker,
    primeSignaturePad,
  },
  utils: {
    enums
  },
  pages: {
    404: page404,
    500: page500
  },
  databases,
  account: {
    login: accountLogin,
    passwordReset: accountPasswordReset,
    passwordChange: accountPasswordChange,
  },
  attendance,
  calendar: Object.assign(calendar, {
    appointment: calendarAppointment,
    waitlist: calendarWaitlist,
    appointmentReminder: calendarReminder
  }),
  dashboard,
  forecast: {
    list: forecastList
  },
  email,
  incomes: {
    income: income,
    list: incomesList,
    preview: incomesPreview,
    saveBeforePopup: incomeSaveBeforePopup,
    saveAfterPopup: incomeSaveAfterPopup,
    previewPopup: incomePreviewPopup,
    addDocumentPopup: incomeAddDocumentPopup,
    sendPopup: incomeSendPopup,
    relatedDocsPopup: incomeRelatedDocsPopup
  },
  deposit: {
    cash: depositCash,
    checks: depositChecks
  },
  expenses: {
    list: expensesList,
    suppliers: expenseSuppliers,
    types: expenseTypes
  },
  laboratoryWorks: {
    laboratoryWork: laboratoryWork,
    list: laboratoryWorksList,
    laboratories: laboratoryWorkLaboratories,
    laboratory: laboratoryWorkLaboratory
  },
  leads: {
    list: leadList,
    lead: lead,
    channels: leadChannels,
    channel: leadChannel,
  },
  patients: {
    patient: patient,
    list: patientsList,
    prescriptions: patientPrescriptions,
    documents: patientDocuments,
    medicalForms: patientMedicalForms,
    medicalRecords: patientMedicalRecords,
    files: patientFiles,
    tasks: patientTasks,
    treatments: patientTreatments,
    treatmentPlans: patientTreatmentPlans,
    laboratoryWorks: patientLaboratoryWorks,
    family: patientFamily,
    payments: patientPayments,
  },
  treatments: {
    treatment: treatment,
  },
  recall,
  reports: {
    accounting: reportsAccounting,
    advanceTax: reportsAdvanceTax,
    collection: reportsCollection,
    expenses: reportsExpenses,
    income: reportsIncome,
    overview: reportsOverview,
    profitAndLoss: reportsProfitAndLoss,
    vat: reportsVat
  },
  settings: {
    business: settingsBusiness,
    pricelist: settingsPricelist,
    pricelistGroups: settingsPricelistGroups,
    pricelistGroup: settingsPricelistGroup,
    users: settingsUsers,
    user: settingsUser,
    externalCalendars: externalCalendars,
    services: settingsServices,
    service: settingsService,
    serviceCategories: settingsServiceCategories,
    serviceCategory: settingsServiceCategory,
    templates: {
      documents: settingsTemplatesDocuments,
      document: settingsTemplatesDocument,
      messages: settingsTemplatesMessages,
      message: settingsTemplatesMessage,
      prescriptions: settingsTemplatesPrescriptions,
      prescription: settingsTemplatesPrescription
    }
  },
  tasks: {
    task: task,
    list: tasksList
  },
  medicalRecords: {
    medicalRecord: medicalRecord
  }
}

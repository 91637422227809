export default {
  title: "MedForm",
  description: "MedForm",

  format: {
    dateTime: "DD/MM/YYYY HH:mm",
    dateTimeWithDay: "DD/MM/YYYY HH:mm (dd)",
    dateTimeWithWeekDay: "DD/MM/YYYY HH:mm (dddd)",
    date: "DD/MM/YYYY",
    time: "HH:mm",
    monthWithYear: "MM/YYYY"
  }
};

export default {
  databases: { text: "בסיסי נתונים" },
  dashboard: { text: "דשבורד" },
  calendar: { text: "יומן" },
  patients: { text: "מטופלים" },
  chat: { text: "הודעות" },
  email: { text: 'הודעות דוא"ל' },
  financial: {
    text: "הנהלת חשבונות", // *
    items: {
      incomes: { text: "הכנסות" },
      expenses: {
        text: "הוצאות",
        items: {
          expenses: { text: "רשימת הוצאות" },
          suppliers: { text: "ספקים" },
          types: { text: "סוגי הוצאות" },
        }
      },
      deposit: {
        text: "הפקדות", // *
        items: {
          cash: { text: "הפקדת מזומן" },
          check: { text: "הפקדת צ'קים" },
        }
      },
      forecast: { text: "תזרים" },
      reports: {
        text: "דוחות", // *
        items: {
          overview: { text: "מבט על" },
          collection: { text: "דוח חייבים" },
          income: { text: "דוח הכנסות" },
          expenses: { text: "דוח הוצאות" },
          profitAndLoss: { text: "דוח רווח והפסד" },
          vat: { text: "דוח מע\"מ" },
          advanceTax: { text: "דוח מקדמות" },
          accounting: { text: "דוח התחשבנות" },
        }
      },
    }
  },
  recalls: { text: "ריקול" },
  leads: { text: "CRM" },
  tasks: { text: "ניהול משימות" },
  laboratoryWorks: { text: "עבודות מעבדה" },
  attendance: { text: "דיווח שעות" },
  settings: {
    text: "הגדרות", // *
    items: {
      business: { text: "הגדרות עסק" },
      users: { text: "ניהול משתמשים" },
      services: { text: "תבניות טיפול" },
      priceList: { text: "מחירון" },
      documentTemplates: { text: "תבניות הפניה" },
      messageTemplates: { text: "תבניות הודעה" },
      prescriptionTemplates: { text: "תבניות מרשם" },
    }
  },
}

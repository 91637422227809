import GraphqlService from "../GraphqlService";
import { type MessageProvider, MessageTemplateType } from "@/models/enums";
import { type MessageTemplateSchema, messageTemplateSchemaAllFields as fields } from "@/models/schemas";
import type { TabulatorParams, Filter, Result } from "@/models/interfaces";
import type { SaveMessageTemplateInput } from "@/models/api/mutations/MessageTemplateModels";

export default class MessageTemplateService {
  async getMessageTemplates(params: TabulatorParams) {
    return await GraphqlService.getItems<MessageTemplateSchema>("messageTemplates", fields, params);
  }

  async getMessageTemplatesAll(filter?: Filter[]) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema[]>({ method: "messageTemplatesAll", fields: fields, filter: filter })
    return data ?? [];
  }

  async getMessageTemplateAttachmentIds(id: number) {
    const { data } = await GraphqlService.queryGql<Result<number[]>>({ method: "messageTemplateAttachmentIds", variables: [{ field: "id", value: id, valueType: "ID!" }] })
    return data?.data ?? [];
  }

  async deleteMessageTemplate(id: number) {
    return await GraphqlService.deleteItem("deleteMessageTemplate", id);
  }

  async getMessageTemplate(id: number) {
    return await GraphqlService.getItem<MessageTemplateSchema>("messageTemplate", fields, id);
  }

  async getMessageTemplateForReminder(id: number, appointmentId: number) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema>({
      method: "messageTemplateForReminderMessage",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
      ],
      fields: fields,
    });
    return data;
  }

  async getMessageTemplateForRecall(id: number, appointmentId: number, newAppointmentId?: number) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema>({
      method: "messageTemplateForRecallMessage",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
        { field: "newAppointmentId", value: newAppointmentId, valueType: "Int" },
      ],
      fields: fields,
    });
    return data;
  }

  async getMessageTemplatesForFinancialDocument(incomeId: number) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema[]>({
      method: "messageTemplatesForFinancialDocument", fields: fields, variables: [{ field: "id", value: incomeId, valueType: "ID!" }]
    });
    return data ?? [];
  }

  async getMessagesTemplateByPlatform(platfom: MessageProvider) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema[]>({
      method: "messageTemplatesAll",
      fields: fields,
      filter: [
        { field: "active", type: "=", value: true },
        { field: "platform", type: "=", value: platfom },
        { field: "type", type: "=", value: MessageTemplateType.General }
      ],
    });
    return data ?? [];
  }

  async saveMessageTemplate(input: SaveMessageTemplateInput) {
    return await GraphqlService.setItem<MessageTemplateSchema>("saveMessageTemplate", "messageTemplate", fields, input);
  }
}

import type { NotificationSchema } from "@/models/schemas/NotificationSchema";
import { useServices } from "@/services";
import GraphqlService from "@/services/GraphqlService";
import { AutoMapper } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

const subscription = ref<any>(null);

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: () => ({
    currentNotifications: [] as NotificationSchema[],
    currentNotificationsCount: 0 as number,
  }),
  actions: {
    async fetchCurrentNotifications() {
      try {
        const { notifications } = await useServices().notification.getCurrentNotifications();
        const notificationItems: NotificationSchema[] = [];
        notifications.items.forEach((notification: NotificationSchema, index: number) => {
          const newNotification = {} as NotificationSchema;
          AutoMapper.map(notification, newNotification, true);
          notificationItems.push(newNotification);
        });
        this.currentNotifications = notificationItems;
        this.currentNotificationsCount = notifications.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    async subscribe() {
      const notificationStore = this;
      if (!subscription.value) {
        subscription.value = await GraphqlService.subscribeSchema("NotificationSchema", {
          onResult(data) {
            notificationStore.fetchCurrentNotifications();
          },
          onError(error) {
            console.log("[notifications] subscribe", error);
            subscription.value = null;
            setTimeout(() => notificationStore.subscribe(), 3000);
          }
        });
      }
    },
  }
})

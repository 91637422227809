import { createWebHistory, createRouter } from "vue-router";
import { useServices } from "@/services";
import { IdentityHandler } from "@/services/IdentityHandler";
import { PopupLegacy, Popup } from "@/utils";
import { i18n } from "@/locales/i18n";

// import VueMeta from 'vue-meta'

import routes from './routes'

// Vue.use(VueRouter)
// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: 'page',
// })

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  if (authRequired && !useServices().apiAuth.isSignedIn) {
    return next({ name: "login", query: { redirectFrom: routeTo.fullPath } })
  }

  // Check policy
  const policy = routeTo.meta.policy as string;
  if (policy && !await IdentityHandler.checkPolicyAsync(policy as any)) {
    Popup.notify({ text: `No access to policy "${policy}"`, type: "error" });
    return next({ name: "default" });
  }

  // Check unload
  const preventUnload = routeFrom.meta.preventUnload as boolean | string | Promise<boolean> | undefined;
  if (preventUnload && !routeTo.query.preventUnloadSkip) {
    switch (typeof preventUnload) {
      case "boolean":
      case "string":
        const message = preventUnload === true ? i18n.global.t('default.unloadMessage') : preventUnload
        const result = await PopupLegacy.confirm({ html: message });
        return next(result);
    }
    const result = await preventUnload;
    return next(result);
  }

  next();
})

router.beforeResolve(async (routeTo, routeFrom, next: any) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve: any, reject: any) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          (<any>route.meta).beforeResolve(routeTo, routeFrom, (...args: any) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router

import type { SaveSupportMessageInput } from "@/models/api/mutations/SupportMessageModels";
import GraphqlService from "../GraphqlService";

export default class SupportMessageService {
  async saveSupportMessage(input: SaveSupportMessageInput) {
    return await GraphqlService.mutateGql<boolean>({
      method: "saveSupportMessage", variables: [
        { field: "email", value: input.email, valueType: "String!" },
        { field: "url", value: input.url, valueType: "String!" },
        { field: "content", value: input.content, valueType: "String!" },
      ]
    });
  }
}

<template>
  <div class="p-formkit">
    <MultiSelect
      v-model="context._value"
      :input-id="context.id"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :readonly="attrs._readonly ?? context.readonly ?? false"
      :list-style="attrs.style"
      :class="styleClass"
      :tabindex="attrs.tabindex"
      :aria-label="attrs.ariaLabel"
      :aria-labelledby="attrs.ariaLabelledby"
      :placeholder="attrs.placeholder"
      :options="attrs.options"
      :option-label="attrs.optionLabel ?? 'label'"
      :option-value="attrs.optionValue ?? 'value'"
      :filter="attrs.filter ?? true"
      :display="attrs.display"
      :max-selected-labels="attrs.maxSelectedLabels"
      :selected-items-label="attrs.selectedItemsLabel"
      :selection-limit="attrs.selectionLimit"
      :show-toggle-all="attrs.showToggleAll"
      :pt="attrs.pt"
      :pt-options="attrs.ptOptions"
      :unstyled="attrs.unstyled ?? false"
      :scroll-height="attrs.scrollHeight"
      @change="handleChange"
      @before-show="MultiSelectFixRtlPosition(`.${panelClass}`)"
      :panel-class="panelClass">
      <template #value="slotProps">
        <span class="text-truncate-1-line" v-if="slotProps?.value">
          <span v-if="!slotProps.value.length">{{ slotProps.placeholder || t("default.select") + "..." }}</span>
          <span v-else v-for="(value, index) in slotProps.value" :key="value">
            <span v-if="index > 0">, </span>
            <span>{{ attrs.options.find((x: any) => x.value == value)?.label ?? value }}</span>
          </span>
        </span>
      </template>
    </MultiSelect>
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/main/src/components/PrimeMultiSelect.vue -->

<script lang="ts">
  import { nextTick } from "vue";
  // https://github.com/primefaces/primevue/issues/4983
  // https://github.com/primefaces/primevue/issues/772
  export function MultiSelectFixRtlPosition(panelSelector: string) {
    if (document.dir !== "rtl") return;
    let attempts = 10;
    function fixPanelPosition() {
      const panel = document.querySelector(panelSelector) as HTMLDivElement;
      // call nextTick maximum 10 times
      if (!panel && attempts > 0) {
        attempts--;
        nextTick(fixPanelPosition);
        return;
      }
      // fix rtl position
      const offsetY = panel.offsetWidth - parseInt(panel.style.minWidth);
      panel.style.marginLeft = "unset";
      panel.style.marginLeft = -Math.min(offsetY, panel.getBoundingClientRect().left) + "px";
    }
    nextTick(fixPanelPosition);
  }
</script>
<script setup lang="ts">
  import { v4 as uuid } from "uuid";
  import MultiSelect from "primevue/multiselect";
  import { ref, computed } from "vue";
  import { useI18n } from "vue-i18n";

  const props = defineProps<{
    context: any;
  }>();

  const { t } = useI18n();

  const context = props.context;
  const attrs = computed(() => context?.attrs);
  const panelClass = ref("multiselect-" + uuid());

  function handleChange(e: any) {
    context?.node.input(props.context?._value);
    MultiSelectFixRtlPosition(`.${panelClass.value}`);
  }

  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class));
</script>

<style lang="scss">
  .p-multiselect {
    .p-multiselect-label {
      padding: 0;

      .text-truncate-1-line {
        max-width: 230px;
        // min-width: 180px;
        text-overflow: ellipsis;
        display: block;
      }
    }

    .p-multiselect-label-empty {
      visibility: unset;
    }

    .p-multiselect-trigger {
      display: none;
    }
  }

  .p-multiselect-panel {
    z-index: 1055 !important;

    .p-multiselect-header {
      padding: 0.25rem 0.5rem;
    }

    .p-multiselect-items {
      padding: 0;

      .p-multiselect-item {
        padding: 0.5rem;
      }
    }
  }
</style>

import gql from "graphql-tag";
import moment from "@/plugins/VueMomentPlugin";
import GraphqlService from "../GraphqlService";
import { useApolloClient } from "@vue/apollo-composable";
import { laboratoryWorkSchemaAllFields, type LaboratoryWorkSchema } from "@/models/schemas";
import type { FilterLaboratoryWorkInput } from "@/models/api/queries/LaboratoryWorkModels";
import type { FilterLaboratoryWorkNoteInput } from "@/models/api/queries/LaboratoryWorkNoteModels";
import type { LaboratoryWorkNoteSchema } from "@/models/schemas/LaboratoryWorkNoteSchema";
import type { LaboratoryWorkStatus } from "@/models/enums";
import type { SaveLaboratoryWorkInput } from "@/models/api/mutations/LaboratoryWorkModels";
import type { SaveLaboratoryWorkNoteInput } from "@/models/api/mutations/LaboratoryWorkNoteModels";
import type { TabulatorParams } from "@/models/interfaces";
import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";

const fieldsLaboratoryWorkNote = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "laboratoryWorkId",
  "text"
];

export default class LaboratoryWorkService {
  async getLaboratoryWorks(fields: string[], size: number, page: number, userIds: number[], status?: LaboratoryWorkStatus) {
    const params: TabulatorParams = {
      size: size,
      page: page,
      filter: [{ field: "status", type: "=", value: status }],
      sort: [{ field: "id", dir: "desc" }]
    };
    if (userIds.length) {
      params.filter.push({ field: "defaultDoctorId", type: "in", value: userIds, valueType: "[Int!]" });
    }
    return await GraphqlService.getItems<LaboratoryWorkSchema>("laboratoryWorks", fields, params);
  }

  async getLaboratoryWorksTabulator(params: TabulatorParams, input?: FilterLaboratoryWorkInput, fields?: string[]) {
    return await GraphqlService.getItems<LaboratoryWorkSchema>("laboratoryWorks", fields ?? laboratoryWorkSchemaAllFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterLaboratoryWorkInput" }]
    });
  }

  async getLaboratoryWork(id: number, fields?: string[]) {
    return await GraphqlService.getItem<LaboratoryWorkSchema>("laboratoryWork", fields ?? laboratoryWorkSchemaAllFields, id);
  }

  async saveLaboratoryWork(input: SaveLaboratoryWorkInput, fields: string[]) {
    if (!input.id) {
      input.id = 0;
    }
    if (!input.note) {
      input.note = "";
    }

    return await GraphqlService.setItem<LaboratoryWorkSchema>("saveLaboratoryWork", "laboratoryWork", fields, input);
  }

  async openLaboratoryWork(id: number) {
    return await GraphqlService.deleteItem("openLaboratoryWork", id);
  }

  async closeLaboratoryWork(id: number) {
    return await GraphqlService.deleteItem("closeLaboratoryWork", id);
  }

  async getLaboratoryWorkNotes(size: number, page: number, laboratoryWorkId?: number, fields?: string[] | null) {
    const params: TabulatorParams = {
      size: size,
      page: page,
      filter: [],
      sort: [{ field: "id", dir: "desc" }]
    };

    if (fields == null || fields.length === 0) {
      fields = fieldsLaboratoryWorkNote;
    }

    if (laboratoryWorkId) {
      params.filter.push({ field: "laboratoryWorkId", type: "=", value: laboratoryWorkId });
    }
    return await GraphqlService.getItems<LaboratoryWorkNoteSchema>("laboratoryWorkNotes", fields, params);
  }

  async getLaboratoryWorkNotesTabulator(params: TabulatorParams, laboratoryWorkId?: number, fields?: string[] | null, input?: FilterLaboratoryWorkNoteInput) {
    if (fields == null || fields.length === 0) {
      fields = fieldsLaboratoryWorkNote;
    }

    if (laboratoryWorkId) {
      params.filter.push({ field: "laboratoryWorkId", type: "=", value: laboratoryWorkId });
    }

    return await GraphqlService.getItems<LaboratoryWorkNoteSchema>("laboratoryWorkNotes", fields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterLaboratoryWorkNoteInput" }]
    });
  }

  async getLaboratoryWorkNote(id: number, fields?: string[]) {
    if (fields == null || fields.length === 0) {
      fields = fieldsLaboratoryWorkNote;
    }
    return await GraphqlService.getItem<LaboratoryWorkNoteSchema>("laboratoryWorkNote", fields, id);
  }

  async saveLaboratoryWorkNote(input: SaveLaboratoryWorkNoteInput, fields: string[]) {
    return await GraphqlService.setItem<LaboratoryWorkNoteSchema>("saveLaboratoryWorkNote", "laboratoryWorkNote", fields, input);
  }

  async getCurrentLaboratoryWorks() {
    const { client } = useApolloClient();
    let today = moment().format('YYYY-MM-DDT23:59:59');
    const { data } = await client.query<{ laboratoryWorks: QueryOffsetPage<LaboratoryWorkSchema> }>({
      query: gql`
        query currentLaboratoryWorks {
          laboratoryWorks(
            take: 100
            where: {
              status: {neq:CLOSED}
              followUp: {lte:"${today}"}
            }
            order: { followUp: DESC }
          )
          {
            totalCount
            items {
              id
              followUp
              status
              laboratoryId
              laboratoryName
              laboratoryPhone
              patientId
              patientName
              patientFirstName
              patientLastName
              patientPhoto
              patientPhone1
              patientPhone2
              patientPhone3
              title
              __typename
            }
          }
        }
      `,
      variables: {}
    });

    return data;
  }

}

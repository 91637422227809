export default {
  title: "Medical records",
  table: {
    columns: {
      id: "ID",
      createdAt: "Date",
      doctorName: "Doctor",
      description: "Description"
    }
  }
}

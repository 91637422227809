import GraphqlService from "../GraphqlService";
import {
  incomeSchemaAllFields, type IncomeSchema,
  invoiceLineSchemaAllFields, type InvoiceLineSchema,
  receiptLineSchemaAllFields, type ReceiptLineSchema
} from "@/models/schemas";
import type { SaveIncomeInput } from "@/models/api/mutations/IncomeModels";
import type { FilterIncomeInput, IncomeSettingsModel } from "@/models/api/queries/IncomeModels";
import type { IncomeType, IncomeStatus, MessageProvider } from "@/models/enums";
import type { TabulatorParams, Result } from "@/models/interfaces";

export default class IncomeService {
  async getNextFinancialNumber(type: IncomeType) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "nextFinancialNumber",
      variables: [{ field: "type", value: type, valueType: "IncomeType!" }]
    })
    return data ?? 0;
  }

  //#region Incomes
  async getIncomes(params: TabulatorParams, input?: FilterIncomeInput) {
    return await GraphqlService.getItems<IncomeSchema>("incomes", incomeSchemaAllFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterIncomeInput" }]
    });
  }

  async getRelatedIncomes(params: TabulatorParams, id: number, fields?: string[]) {
    return await GraphqlService.getItems<IncomeSchema>("relatedIncomes", fields ?? incomeSchemaAllFields, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getIncome(id: number, fields?: string[]) {
    return await GraphqlService.getItem<IncomeSchema>("income", fields ?? incomeSchemaAllFields, id);
  }

  async getIncomesUnpaid(limit: number, page: number) {
    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [],
      sort: [{ field: "documentDate", dir: "asc" }]
    }
    return await GraphqlService.getItems<IncomeSchema>("incomesUnpaid", incomeSchemaAllFields, params);
  }

  async getLastTemplatedIncome(type: IncomeType) {
    const { data } = await GraphqlService.queryGql<IncomeSchema>({
      method: "lastTemplatedIncome", fields: incomeSchemaAllFields, variables: [{
        field: "type", value: type, valueType: "IncomeType!"
      }]
    })
    return data;
  }

  async getIncomeSettingsModel(type: IncomeType) {
    const { data } = await GraphqlService.queryGql<IncomeSettingsModel>({
      method: "incomeSettingsModel",
      fields: ["defaultRounding", "vatType", "nextFinancialNumber", "documentContent"],
      variables: [{ field: "type", value: type, valueType: "IncomeType!" }],
    })
    return data;
  }

  async saveIncome(input: SaveIncomeInput) {
    const { data } = await GraphqlService.mutateGql<number | null>({
      method: "saveIncome",
      variables: [{ field: "input", value: input, valueType: "SaveIncomeInput" }],
    });
    return data;
  }

  async updateIncomeStatus(id: number, status: IncomeStatus) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "updateIncomeStatus",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "status", value: status, valueType: "IncomeStatus!" },
      ]
    });
    return data ?? false;
  }

  async sendIncomeFile(id: number, provider: MessageProvider, destinations: string[], subject?: string, text?: string) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "sendIncomeFile",
      fields: "success message exception",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "provider", value: provider, valueType: "MessageProvider!" },
        { field: "destinations", value: destinations, valueType: "[String]!" },
        { field: "subject", value: subject, valueType: "String" },
        { field: "text", value: text, valueType: "String" }
      ]
    });
    return data;
  }

  async allowIncomePaymentLink(id: number, provider: MessageProvider, destinations: string[]) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "allowIncomePaymentLink",
      fields: "success message exception",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "provider", value: provider, valueType: "MessageProvider!" },
        { field: "destinations", value: destinations, valueType: "[String]!" },
      ]
    });
    return data;
  }

  async removeDraftIncome(id: number) {
    return await GraphqlService.deleteItem("removeDraftIncome", id);
  }
  //#endregion

  //#region Invoice lines
  async getIncomeInvoiceLinesAll(incomeId: number) {
    const { data } = await GraphqlService.queryGql<InvoiceLineSchema[]>({
      method: "incomeInvoiceLinesAll", fields: invoiceLineSchemaAllFields, variables: [{
        field: "id", value: incomeId, valueType: "ID!"
      }]
    });
    return data ?? [];
  }

  async removeDraftInvoiceLine(incomeId: number, lineId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "removeDraftInvoiceLine",
      variables: [
        { field: "id", value: incomeId, valueType: "ID!" },
        { field: "lineId", value: lineId, valueType: "ID!" },
      ]
    });
    return data ?? false;
  }
  //#endregion

  //#region Receipt lines
  async getIncomeReceiptLinesAll(incomeId: number) {
    const { data } = await GraphqlService.queryGql<ReceiptLineSchema[]>({
      method: "incomeReceiptLinesAll", fields: receiptLineSchemaAllFields, variables: [{
        field: "id", value: incomeId, valueType: "ID!"
      }]
    });
    return data ?? [];
  }

  async removeDraftReceiptLine(incomeId: number, lineId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "removeDraftReceiptLine",
      variables: [
        { field: "id", value: incomeId, valueType: "ID!" },
        { field: "lineId", value: lineId, valueType: "ID!" },
      ]
    });
    return data ?? false;
  }
  //#endregion
}

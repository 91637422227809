import type { PricelistPriceSchema, PricelistSchema } from "@/models/schemas";
import type { PricelistGroupSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";

import GraphqlService from "../GraphqlService";
import type { SavePricelistGroupInput, SavePricelistPriceInput, UpdatePricelistPricesInput } from "@/models/api/mutations/PricelistModels";

const fields = `
  serviceName
  serviceId
  prices
  categoryName
`;

const pricelistPriceFields = `
  pricelistId
  price
  serviceId
`;

const pricelistGroupFields = `
  id
  createdAt
  updatedAt
  deletedAt
  title
  isDefault
  active
  displayOrder
`

export default class PricelistService {
  async getPricelists(params: TabulatorParams) {
    return await GraphqlService.getItems<PricelistSchema>("pricelists", fields, params);
  }

  async getPricelistByService(id: number) {
    return await GraphqlService.getItem<PricelistSchema>("pricelistByService", fields, id);
  }

  async getPricelistServicePrice(id: number, serviceId: number, defaultOnZero?: boolean) {
    return await GraphqlService.getItem<PricelistPriceSchema>("pricelistServicePrice", pricelistPriceFields, id, {
      variables: [
        { field: "serviceId", value: serviceId, valueType: "ID!" },
        { field: "defaultOnZero", value: defaultOnZero ?? false, valueType: "Boolean!" },
      ]
    });
  }

  async getPricelistGroupsAll(selectedId?: number) {
    const { data } = await GraphqlService.queryGql<PricelistGroupSchema[]>({
      method: "pricelistGroupsAll",
      fields: pricelistGroupFields,
      variables: [{ field: "selectedId", value: selectedId, valueType: "ID" }]
      // order: [{ field: "title", value: "ASC" }]
    })
    return data ?? [];
  }

  async savePricelistServicePrice(input: SavePricelistPriceInput) {
    return await GraphqlService.setItem<PricelistPriceSchema>("savePricelistPrice", "pricelistServicePrice", pricelistPriceFields, input);
  }

  async getPricelistGroups(params: TabulatorParams) {
    return await GraphqlService.getItems<PricelistGroupSchema>("pricelistGroups", pricelistGroupFields, params);
  }

  async deletePricelistGroup(id: number) {
    return await GraphqlService.deleteItem("deletePricelistGroup", id);
  }

  async getPricelistGroup(id: number) {
    return await GraphqlService.getItem<PricelistGroupSchema>("pricelistGroup", pricelistGroupFields, id);
  }

  async savePricelistGroup(input: SavePricelistGroupInput) {
    return await GraphqlService.setItem<PricelistGroupSchema>("savePricelistGroup", "pricelist", pricelistGroupFields, input);
  }

  async updatePricelistPrices(input: UpdatePricelistPricesInput) {
    return await GraphqlService.mutateGql<boolean>({
      method: "updatePricelistPrices", variables: [
        { field: "pricelistId", value: input.pricelistId, valueType: "Int!" },
        { field: "percentage", value: input.percentage, valueType: "Float!" },
        { field: "priceOperationType", value: input.priceOperationType, valueType: "PriceOperationType!" },
      ]
    });
  }
}

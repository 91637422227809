export default {
  head: {
    title: "הודעות",
    meta: [
      { name: "description", content: "הודעות" }
    ]
  },
  addNewPatient: "ליצור מטופל חדש",
  chatSettings: {
    title: "הגדרות צ'אט",
    tooltip: "הגדרות WhatsApp וסמס",
    options: {
      whatsapp: "הגדרות WhatsApp",
      sms: "הגדרות סמס"
    }
  },
  clearChat: "ניקוי צ'ט",
  clearThisChat: "אם לנקות את הצ'ט?",
  clientNotRegistered: "מטופל {0} לא רשום במערכת",
  clientSearch: "חיפוש מטופלים לפני שם או מספר טלפון",
  copy: "העתק",
  enterMessage: "הקלדת הודעה",
  fileSavedInPatientFolder: "הקובץ נשמר בתיקיית מטופל",
  fileWasNotSavedInPatientFolder: "הקובץ לא נשמר",
  messageSent: "ההודעה נשלחה",
  muteNotifications: "אל תציג התראות על שיחה",
  noSearchResultsFound: "אין תוצאות",
  saveInPatientFolder: "שמור בתיקיית מטופל",
  search: "חיפוש בשיחה",
  sendingSms: "(שליחת sms)",
  sendingWhatsapp: "(שליחת whatsapp)",
  smsMessage: "הודעת SMS",
  unmuteNotifications: "בטל השתק לשיחה",
  whatsappMessage: "הודעת WhatsApp",
  noActiveWhatsapp: "לא מחובר חשבון WhatsApp",
  noActiveSms: "לא מחובר חשבון Sms",
}

import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";
import type { TabulatorParams, Filter } from "@/models/interfaces";
import { useApolloClient, useSubscription } from "@vue/apollo-composable";
import type { QueryOptions, OperationVariables, MutationOptions, DefaultContext } from "@apollo/client";
import type { ApolloError, GraphQLErrors } from "@apollo/client/errors";
import gql from "graphql-tag";

export interface Variable {
  field: string;
  value: any;
  valueType: string;
}

export interface Order {
  field: string,
  value: "ASC" | "DESC"
}

export interface QueryGetOptions {
  variables?: Variable[],
  context?: DefaultContext
}

export interface QueryGqlOptions {
  method: string,
  fields?: string | string[],
  variables?: Variable[],
  filter?: Filter[],
  order?: Order[],
  context?: DefaultContext
}

export interface MutateGqlOptions {
  method: string,
  item?: string,
  fields?: string | string[],
  variables?: Variable[],
  order?: Order[],
  context?: DefaultContext
}

export default class GraphqlService {
  private static parseApiFields(fields?: string | string[]) {
    if (Array.isArray(fields)) {
      fields = fields.join('\r\n');
    }
    return fields ? `{\r\n${fields}\r\n}` : '';
  }

  private static parseApiVariables(apiVariables?: Variable[], order?: Order[], filter?: Filter[]) {
    let variables = {} as any;
    let queryStr = '';
    let methodStr = '';
    // variables
    apiVariables?.filter(v => v.valueType && v.value !== undefined).forEach(v => {
      variables[v.field] = v.value;
      queryStr += `, $${v.field}: ${v.valueType}`;
      methodStr += `, ${v.field}: $${v.field}`
    });
    // order
    if (order?.length) {
      methodStr += ", order: {";
      for (let i = 0; i < order.length; i++) {
        if (i > 0) methodStr += ", ";
        methodStr += `${order[i].field}:${order[i].value}`
      }
      methodStr += "}";
    }
    // filter
    let where = {} as any;
    if (filter?.length) {
      filter.forEach((f, i) => {
        if (f.value === undefined) return;
        if (f.type == 'like') f.valueType = "String";
        if (f.valueType) variables[`filter${i}`] = f.value;
        let value = f.valueType ? `$filter${i}` : f.value;
        // https://tabulator.info/docs/5.5/filter
        // https://chillicream.com/docs/hotchocolate/v13/fetching-data/filtering
        switch (f.type) {
          case '=': where[f.field] = Object.assign(where[f.field] ?? {}, { eq: value }); break;
          case '!=': where[f.field] = Object.assign(where[f.field] ?? {}, { neq: value }); break;
          case '<': where[f.field] = Object.assign(where[f.field] ?? {}, { lt: value }); break;
          case '>': where[f.field] = Object.assign(where[f.field] ?? {}, { gt: value }); break;
          case '<=': where[f.field] = Object.assign(where[f.field] ?? {}, { lte: value }); break;
          case '>=': where[f.field] = Object.assign(where[f.field] ?? {}, { gte: value }); break;
          case 'in': where[f.field] = Object.assign(where[f.field] ?? {}, { in: value }); break;
          case 'like': where[f.field] = Object.assign(where[f.field] ?? {}, { contains: value }); break;
          default: console.log(`Filter type "${f.type}" not supported`); break;
        }
        if (f.valueType) queryStr += `, $filter${i}: ${f.valueType}`;
      });
      methodStr += ", where: " + JSON.stringify(where).replace(/"/g, '');
    }
    return {
      value: variables,
      queryStr: queryStr,
      methodStr: methodStr
    }
  }

  static async query<T>(options: QueryOptions<OperationVariables, T>) {
    const { client } = useApolloClient();
    const { data, errors } = await client.query(options);
    if (errors?.length) {
      throw errors;
    }
    return data;
  }

  static async queryGql<T>(options: QueryGqlOptions): Promise<{ data: T | null, error?: ApolloError, message?: string }> {
    // fields
    const fieldsStr = this.parseApiFields(options.fields);
    // variables
    const variables = this.parseApiVariables(options.variables, options.order, options.filter);
    const queryStr = variables.queryStr ? `(${variables.queryStr.substring(2)})` : '';
    const methodStr = variables.methodStr ? `(${variables.methodStr.substring(2)})` : '';
    // query
    const { client } = useApolloClient();
    try {
      const { data, error } = await client.query({
        query: gql`
        query ${options.method}${queryStr} {
          ${options.method}${methodStr} ${fieldsStr}
        }
      `,
        variables: variables.value,
        context: options.context
      });
      const result = data[options.method];
      if (Array.isArray(result)) {
        return { data: result.map(x => Object.assign({}, x)) as T, error };
      } else if (typeof result === 'object') {
        return { data: result ? Object.assign({}, result) as T : null, error };
      } else {
        return { data: result as T, error };
      }
    } catch (error: any) {
      console.error(error);
      return { data: null, error: error, message: error?.networkError?.result?.errors[0]?.message };
    }
  }

  static async mutateGql<T>(options: MutateGqlOptions) {
    // fields
    const fieldsStr = this.parseApiFields(options.fields);
    // variables
    const variables = this.parseApiVariables(options.variables, options.order);
    const queryStr = variables.queryStr ? `(${variables.queryStr.substring(2)})` : '';
    const methodStr = variables.methodStr ? `(${variables.methodStr.substring(2)})` : '';
    // query
    const { client } = useApolloClient();
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation ${options.method}${queryStr} {
          ${options.method}${methodStr} ${options.item ? "{" : ""}
            ${options.item ?? ""} ${fieldsStr}
          ${options.item ? "}" : ""}
        }
      `,
      variables: variables.value,
      context: options.context
    });
    if (errors?.length) {
      throw errors;
    }
    if (options.item) {
      return { data: data[options.method][options.item] ? Object.assign({}, data[options.method][options.item]) as T : null }
    } else {
      return { data: data[options.method] ? data[options.method] as T : null };
    }
  }

  static async mutate<T>(options: MutationOptions<any, OperationVariables, DefaultContext>) {
    const { client } = useApolloClient();
    const { data, errors } = await client.mutate(options);
    if (errors?.length) {
      throw errors;
    }
    return data;
  }

  static async getItems<T>(method: string, fields: string | string[], params: TabulatorParams, options?: QueryGetOptions) {
    // fields
    const fieldsStr = this.parseApiFields(fields);
    // variables
    const order = params.sort?.map(sort => ({ field: sort.field, value: sort.dir.toUpperCase() } as Order));
    const variables = this.parseApiVariables(options?.variables, order, params.filter);
    // query
    const { client } = useApolloClient();
    const { data, errors } = await client.query({
      query: gql`
        query ${method}($take: Int!, $skip: Int!${variables.queryStr}) {
          ${method}(take: $take, skip: $skip${variables.methodStr}) {
            totalCount
            items ${fieldsStr}
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
      `,
      variables: Object.assign(variables.value, {
        take: params.size,
        skip: params.size * (params.page - 1),
      }),
      context: options?.context
    });
    if (errors?.length) {
      throw errors;
    }
    return data[method] as QueryOffsetPage<T>;
  }

  static async getItem<T>(method: string, fields: string | string[], id: number, options?: QueryGetOptions) {
    // fields
    const fieldsStr = this.parseApiFields(fields);
    // variables
    const variables = this.parseApiVariables(options?.variables);
    // query
    const { client } = useApolloClient();
    const { data, errors } = await client.query({
      query: gql`
        query ${method}($id: ID!${variables.queryStr}) {
          ${method}(id: $id${variables.methodStr}) ${fieldsStr}
        }
      `,
      variables: Object.assign(variables.value, {
        id: id
      }),
      context: options?.context
    });
    if (errors?.length) {
      throw errors;
    }
    return data[method] ? Object.assign({}, data[method]) as T : null;
  }

  static async setItem<T>(method: string, item: string, fields: string | string[], input: any, options?: QueryGetOptions) {
    // fields
    const fieldsStr = this.parseApiFields(fields);
    // variables
    const variables = this.parseApiVariables(options?.variables);
    // query
    const { client } = useApolloClient();
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation ${method}($input: ${method[0].toUpperCase() + method.slice(1)}Input${variables.queryStr}) {
          ${method}(input: $input${variables.methodStr}) {
            ${item} ${fieldsStr}
          }
        }
      `,
      variables: Object.assign(variables.value, {
        input: input
      }),
      context: options?.context
    });
    if (errors?.length) {
      throw errors;
    }
    return data[method][item] ? Object.assign({}, data[method][item]) as T : null;
  }

  static async deleteItem(method: string, id: number, options?: QueryGetOptions) {
    // variables
    const variables = this.parseApiVariables(options?.variables);
    // query
    const { client } = useApolloClient();
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation ${method}($id: ID!${variables.queryStr}) {
          ${method}(id: $id${variables.methodStr})
        }
      `,
      variables: Object.assign(variables.value, {
        id: id
      }),
      context: options?.context
    });
    if (errors?.length) {
      throw errors;
    }
    return data[method] as boolean | null;
  }

  static async subscribeSchema(typename: string, observer: {
    onResult: (data: { typename: string, ids: number[], newId: boolean, data: null | any }) => void,
    onError?: (error: any) => void
  }) {
    const method = `${typename[0].toLowerCase() + typename.slice(1)}Stream`;

    const { client } = useApolloClient();
    // https://v4.apollo.vuejs.org/guide-option/subscriptions.html#standard-apollo-subscribe
    const observable = await client.subscribe({
      query: gql`
        subscription ${method} {
          ${method} {
            typename
            ids
            newId
            data
          }
        }
      `
    });
    const subscription = observable.subscribe({
      next(value) {
        observer.onResult(value.data[method]);
      },
      error(errorValue) {
        if (observer.onError) {
          observer.onError(errorValue);
        }
      },
    });
    return subscription;

    // https://v4.apollo.vuejs.org/guide-composable/subscription.html
    // const subscription = useSubscription(gql...);
  }
}

import type { DateTimeString } from "@/models/interfaces";
import type { TreatmentStageStatus } from "@/models/enums";

export interface TreatmentStatusSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;

  title: string;
  color: string;
  stage: TreatmentStageStatus;
}

export const treatmentStatusSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "title",
  "color",
  "stage",
  "order",
];

import { DiscountType } from "@/models/enums";

export class FinancialUtil {
  public static round(value: number, decimals = 2) {
    // https://www.youtube.com/watch?v=kHoWenD1CaY&ab_channel=Samovartutorials
    const factor = Math.pow(10, decimals);
    value += Math.sign(value) * Number.EPSILON;
    return Math.round(value * factor) / factor;
  }
  public static formatPercent(percent?: number | null) {
    if (typeof percent !== "number") return "0.00%";
    return `${percent.toLocaleString("he", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`;
  }

  public static getDiscountValidation(price: number, discountType: DiscountType) {
    switch (discountType) {
      case DiscountType.Percent:
        return "required|between:0,100";
      case DiscountType.Amount:
      case DiscountType.FixPrice:
        return `required|between:0,${price}`;
    }
    return "required";
  }

  public static getDiscountDisplay(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      case DiscountType.Percent:
        return discountValue.toFixed(2) + "%";
      case DiscountType.Amount:
        return discountValue.toFixed(2);
      case DiscountType.FixPrice:
        return (price - discountValue).toFixed(2);
    }
    return 0;
  }

  public static calculateDiscountAmount(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      default:
      case DiscountType.None:
        return 0;
      case DiscountType.Percent:
        return this.round(price * (discountValue / 100));
      case DiscountType.Amount:
        return discountValue;
      case DiscountType.FixPrice:
        return price - discountValue;
    }
  }

  public static calculateDiscountPrice(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      default:
      case DiscountType.None:
        return price;
      case DiscountType.Percent:
        return price - this.round(price * (discountValue / 100));
      case DiscountType.Amount:
        return price - discountValue;
      case DiscountType.FixPrice:
        return discountValue;
    }
  }
}

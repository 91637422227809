import { createApp } from "vue";

import App from "./App.vue";
import { VueMomentPlugin } from "@/plugins/VueMomentPlugin";
import { GraphQLPlugin } from "@/plugins/GraphQLPlugin";
import { FormKitPlugin } from "@/plugins/FormKitPlugin";
import { PrimeVuePlugin } from "@/plugins/PrimeVuePlugin";
import { SentryPlugin } from "@/plugins/SentryPlugin";
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import { createPinia } from "pinia";
import { createHead } from "@vueuse/head";
import BootstrapVueNext from "bootstrap-vue-next";
import { i18n } from "@/locales/i18n";
import Notifications from "@kyvg/vue3-notification";
import router from "./router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/styles/app.scss";

const app = createApp(App)
  .use(VueMomentPlugin)
  .use(GraphQLPlugin)
  .use(FormKitPlugin)
  .use(PrimeVuePlugin)
  .use(SentryPlugin)
  .use(CkeditorPlugin)
  .use(createPinia())
  .use(createHead())
  .use(BootstrapVueNext)
  .use(i18n)
  .use(Notifications)
  .use(router)
  .mount("#app");

export default {
  search: {
    text: "Customer search by phone, last name..."
  },
  supportMessage: {
    add: {
      title: "Support form",
      text: "Please fill in details and a support team will contact you as soon as possible",
      submit: "Send message",
      content: "Message",
      contentPlaceholder: "Message to support",
      success: "Your message has been sent successfully",
      choosetext: "How would you like to contact us?",
      whatsapp: "Contact via WhatsApp",
      chat: "Chat with support",
      emailForm: "Contact by email",
      email: "What email to get back to you?"
    }
  },
  taskNotification: {
    laboratoryWorkTitle: "Laboratory work - {0}"
  },
  notifications: {
    NEW_WEB_APPOINTMENTS_EXISTING_PATIENT: {
      title: "Patient made a new appointment",
      content: "{Patient} schedule a new appointment for {NewDateAndWeekDay} at {Time} - Dr. {Doctor}",
    },
    NEW_WEB_APPOINTMENTS_NEW: {
      title: "New appointment set for new patient",
      content: "{FullName} {Phone} {Email} Appointment details {Doctor} {ServiceName} {AppointmentDate}",
    },
    PATIENT_CANCELED_APPOINTMENT: {
      title: "Patient cancelled appointment",
      content: "{FullName} {Phone} {Email} Appointment details {Doctor} {ServiceName} {AppointmentDate}",
    },
    PATIENT_CHANGED_APPOINTMENT: {
      title: "Patient changed appointment",
      content: "{PatientFullName} changed the appointment date from {OldDateAndWeekDay} to {NewDateAndWeekDay} at {Hour} - Dr. {Doctor}",
    },
    PATIENT_APPROVED_APPOINTMENT: {
      title: "Patient approved appointment",
      content: "{FullName} {Phone} {Email} Appointment details {Doctor} {ServiceName} {AppointmentDate}",
    },
    PATIENT_NOT_APPROVED_APPOINTMENT: {
      title: "Patient still not approved appointment",
      content: "{PatientFullName} still not approved appointment for today's appointment at {AppointmentStartTime} - Dr. {DoctorFullName}",
    },
    PATIENT_FILLED_OUT_MEDICAL_FORM: {
      title: "Patient signed a document",
      content: "{FullName} signed the document {DocumentTitle}"
    },
    MOBILE_REGISTRATION: {
      title: "A new patient has been registered",
      content: "{FullName} is registered as a patient",
    },
    NEW_LEAD: {
      title: "New potential patient registered",
      content: "{LeadFullName} has registered as a new potential patient.{Separator}{ServiceName}",
    }
  },
  quickActions: {
    title: "Quick actions",
    create: {
      income: "New financial document",
      patient: "New patient",
      reception: "Mobile reception",
      task: "New task"
    }
  },
  dropdown: {
    tasks: {
      title: "Tasks",
      viewAll: "View All",
      completed: "Completed"
    },
    henry: {
      text: "Henry",
      list: {
        profile: "Profile",
        logout: "Logout"
      }
    }
  },
  version: "Version",
  notificationsTitle: "Notifications"
}

export default {
  titles: {
    edit: "Medical record edit",
    create: "Medical record create"
  },
  doctor: "Doctor",
  type: "Type",
  types: {
    regular: "Regular",
    bold: "Bold"
  },
  description: "Description",
  submit: "Continue"
}
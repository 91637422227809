import GraphqlService from "@/services/GraphqlService";
import moment from "@/plugins/VueMomentPlugin";
import type { LaboratoryWorkSchema } from "@/models/schemas";
import { AutoMapper } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useServices } from "@/services";
import { StorageFileType } from "@/models/enums";

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/

const subscription = ref<any>(null);

export const useLaboratoryWorkStore = defineStore({
  id: "laboratoryWork",
  state: () => ({
    currentLaboratoryWorks: [] as LaboratoryWorkSchema[],
    currentLaboratoryWorksCount: 0 as number,
    listOfLaboratoryWorksForNotification: [] as LaboratoryWorkSchema[],
    listOfAlreadyNotifiedLaboratoryWorks: [] as LaboratoryWorkSchema[],
    loadedAt: moment("1900-01-01"),
    firstLoadedAt: moment()
  }),
  actions: {
    clearCurrentLaboratoryWorks() {
      this.currentLaboratoryWorks = [] as LaboratoryWorkSchema[];
      this.currentLaboratoryWorksCount = 0;
      this.listOfLaboratoryWorksForNotification = [] as LaboratoryWorkSchema[];
      this.listOfAlreadyNotifiedLaboratoryWorks = [] as LaboratoryWorkSchema[];
      this.loadedAt = moment("1900-01-01");
      this.firstLoadedAt = moment();
    },
    removeCurrentLaboratoryWork(id: number) {
      this.currentLaboratoryWorks = this.currentLaboratoryWorks.filter((laboratoryWork: LaboratoryWorkSchema) => laboratoryWork.id !== id);
      this.currentLaboratoryWorksCount = this.currentLaboratoryWorksCount - 1;
      this.listOfLaboratoryWorksForNotification = this.listOfLaboratoryWorksForNotification.filter((laboratoryWork: LaboratoryWorkSchema) => laboratoryWork.id !== id);
    },
    removeNotification(id: number) {
      const laboratoryWork = this.listOfLaboratoryWorksForNotification.find((laboratoryWork) => laboratoryWork.id === id);
      if (laboratoryWork) {
        this.listOfAlreadyNotifiedLaboratoryWorks.push(laboratoryWork);
      }
      this.listOfLaboratoryWorksForNotification = this.listOfLaboratoryWorksForNotification.filter((laboratoryWork: LaboratoryWorkSchema) => laboratoryWork.id !== id);
    },
    async subscribe() {
      let laboratoryWorkStore = this;
      if (!subscription.value) {
        subscription.value = await GraphqlService.subscribeSchema("LaboratoryWorkSchema", {
          onResult(data) {
            laboratoryWorkStore.fetchCurrentLaboratoryWorks(true);
          },
          onError(error) {
            subscription.value = null;
            setTimeout(() => laboratoryWorkStore.subscribe(), 3000);
          }
        });
      }
    },
    async fetchCurrentLaboratoryWorks(force: boolean = false) {
      if (!force && moment().diff(this.loadedAt, "seconds") < 60) {
        return;
      }

      try {
        let { laboratoryWorks } = await useServices().laboratoryWork.getCurrentLaboratoryWorks();
        let laboratoryWorkItems = [] as LaboratoryWorkSchema[];
        laboratoryWorks.items.forEach((laboratoryWork: LaboratoryWorkSchema, index: number) => {
          let newTask = {} as LaboratoryWorkSchema;
          AutoMapper.map(laboratoryWork, newTask, true);
          newTask.patientPhoto = useServices().storage.getLink(StorageFileType.PatientPhoto, laboratoryWork.patientPhoto);
          laboratoryWorkItems.push(newTask);
        });
        this.currentLaboratoryWorks = laboratoryWorkItems;
        this.currentLaboratoryWorksCount = laboratoryWorks.totalCount;
        this.loadedAt = moment();
      } catch (error) {
        console.log(error);
      }
    },
    async prepareLaboratoryWorksForNotification() {
      let currentTime = moment();

      this.currentLaboratoryWorks.every((laboratoryWork: LaboratoryWorkSchema) => {
        let taskFollowUpTime = moment(laboratoryWork.followUp);
        if (taskFollowUpTime.isBefore(this.firstLoadedAt)) {
          // we use sorted list of items, so we can stop iteration
          return false;
        }

        if (taskFollowUpTime.isBefore(currentTime)) {
          if (
            !this.listOfLaboratoryWorksForNotification.some((item) => item.id === laboratoryWork.id && item.followUp === laboratoryWork.followUp) &&
            !this.listOfAlreadyNotifiedLaboratoryWorks.some((item) => item.id === laboratoryWork.id && item.followUp === laboratoryWork.followUp)
          ) {
            this.listOfLaboratoryWorksForNotification.push(laboratoryWork);
          }
        }
        return true;
      });
    }
  }
});

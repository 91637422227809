import ApiAuthService from "./api/ApiAuthService";
import AppointmentService from "./api/AppointmentService";
import AppointmentReminderService from "./api/AppointmentReminderService";
import AttachmentService from "./api/AttachmentService";
import AttendanceService from "./api/AttendanceService";
import AttendanceReportService from "./api/AttendanceReportService";
import ConfigService from "./ConfigService";
import ChannelService from "./api/ChannelService";
import DatabaseInfoService from "./api/DatabaseInfoService";
import DepositCashService from "./api/DepositCashService";
import DoctorService from "./api/DoctorService";
import DocumentTemplateService from "./api/DocumentTemplateService";
import EmailService from "./api/EmailService";
import ExportService from "./api/ExportService";
import ExpenseService from "./api/ExpenseService";
import ForecastService from "./api/ForecastService";
import GoogleAuthService from "./api/GoogleAuthService";
import GoogleCalendarApiService from "./api/GoogleCalendarApiService";
import HistoryService from "./api/HistoryService";
import IncomeService from "./api/IncomeService";
import LaboratoryService from "./api/LaboratoryService";
import LaboratoryWorkService from "@/services/api/LaboratoryWorkService";
import LeadService from "./api/LeadService";
import LeadStatusService from "./api/LeadStatusService";
import MessageService from "./api/MessageService";
import MessageTemplateService from "./api/MessageTemplateService";
import NotificationService from "./api/NotificationService";
import PatientService from "./api/PatientService";
import PatientTreatmentService from "./api/PatientTreatmentService";
import PatientWaitlistService from "./api/PatientWaitlistService";
import PaymentService from "./api/PaymentService";
import PdfService from "./api/PdfService";
import PrescriptionTemplateService from "./api/PrescriptionTemplateService";
import PricelistService from "./api/PricelistService";
import RecallService from "./api/RecallService";
import RecallArchiveService from "./api/RecallArchiveService";
import RoleService from "./api/RoleService";
import ReceiptLineService from "./api/ReceiptLineService";
import ReportService from "./api/ReportService";
import ShareService from "./api/ShareService";
import ServiceService from "./api/ServiceService";
import SettingService from "./api/SettingService";
import StorageService from "./api/StorageService";
import SupplierService from "@/services/api/SupplierService";
import SupportMessageService from "./api/SupportMessageService";
import TaskService from "./api/TaskService";
import TimeService from "./api/TimeService";
import TreatmentService from "./api/TreatmentService";
import UserPermissionsService from "./api/UserPermissionsService";
import UserService from "./api/UserService";
import VariablesService from "./api/VariablesService";
import WhatsappService from "./api/WhatsappService";
import ScannersService from "./api/ScannersService";

class ServiceContainer {
  private _apiAuth: any;
  public get apiAuth(): ApiAuthService {
    return this._apiAuth ??= new ApiAuthService();
  }

  private _appointment: any;
  public get appointment(): AppointmentService {
    return this._appointment ??= new AppointmentService();
  }

  private _appointmentReminder: any;
  public get appointmentReminder(): AppointmentReminderService {
    return this._appointmentReminder ??= new AppointmentReminderService();
  }

  private _attachment: any;
  public get attachment(): AttachmentService {
    return this._attachment ??= new AttachmentService();
  }

  private _attendanceReport: any;
  public get attendanceReport(): AttendanceReportService {
    return this._attendanceReport ??= new AttendanceReportService();
  }

  private _attendance: any;
  public get attendance(): AttendanceService {
    return this._attendance ??= new AttendanceService();
  }

  private _channel: any;
  public get channel(): ChannelService {
    return this._channel ??= new ChannelService();
  }

  private _databaseInfo: any;
  public get databaseInfo(): DatabaseInfoService {
    return this._databaseInfo ??= new DatabaseInfoService();
  }

  private _depositCash: any;
  public get depositCash(): DepositCashService {
    return this._depositCash ??= new DepositCashService();
  }

  private _doctor: any;
  public get doctor(): DoctorService {
    return this._doctor ??= new DoctorService();
  }

  private _documentTemplate: any;
  public get documentTemplate(): DocumentTemplateService {
    return this._documentTemplate ??= new DocumentTemplateService();
  }

  private _email: any;
  public get email(): EmailService {
    return this._email ??= new EmailService();
  }

  private _export: any;
  public get export(): ExportService {
    return this._export ??= new ExportService();
  }

  private _expense: any;
  public get expense(): ExpenseService {
    return this._expense ??= new ExpenseService();
  }

  private _forecast: any;
  public get forecast(): ForecastService {
    return this._forecast ??= new ForecastService();
  }

  private _googleAuth: any;
  public get googleAuth(): GoogleAuthService {
    return this._googleAuth ??= new GoogleAuthService();
  }

  private _googleCalendar: any;
  public get googleCalendar(): GoogleCalendarApiService {
    return this._googleCalendar ??= new GoogleCalendarApiService();
  }

  private _history: any;
  public get history(): HistoryService {
    return this._history ??= new HistoryService();
  }

  private _income: any;
  public get income(): IncomeService {
    return this._income ??= new IncomeService();
  }

  private _laboratory: any;
  public get laboratory(): LaboratoryService {
    return this._laboratory ??= new LaboratoryService();
  }

  private _laboratoryWork: any;
  public get laboratoryWork(): LaboratoryWorkService {
    return this._laboratoryWork ??= new LaboratoryWorkService();
  }

  private _lead: any;
  public get lead(): LeadService {
    return this._lead ??= new LeadService();
  }

  private _leadStatus: any;
  public get leadStatus(): LeadStatusService {
    return this._leadStatus ??= new LeadStatusService();
  }

  private _message: any;
  public get message(): MessageService {
    return this._message ??= new MessageService();
  }

  private _messageTemplate: any;
  public get messageTemplate(): MessageTemplateService {
    return this._messageTemplate ??= new MessageTemplateService();
  }

  private _notification: any;
  public get notification(): NotificationService {
    return this._notification ??= new NotificationService();
  }

  private _patient: any;
  public get patient(): PatientService {
    return this._patient ??= new PatientService();
  }

  private _patientTreatment: any;
  public get patientTreatment(): PatientTreatmentService {
    return this._patientTreatment ??= new PatientTreatmentService();
  }

  private _patientWaitlist: any;
  public get patientWaitlist(): PatientWaitlistService {
    return this._patientWaitlist ??= new PatientWaitlistService();
  }

  private _payment: any;
  public get payment(): PaymentService {
    return this._payment ??= new PaymentService();
  }

  private _pdf: any;
  public get pdf(): PdfService {
    return this._pdf ??= new PdfService();
  }

  private _prescriptionTemplate: any;
  public get prescriptionTemplate(): PrescriptionTemplateService {
    return this._prescriptionTemplate ??= new PrescriptionTemplateService();
  }

  private _pricelist: any;
  public get pricelist(): PricelistService {
    return this._pricelist ??= new PricelistService();
  }

  private _recall: any;
  public get recall(): RecallService {
    return this._recall ??= new RecallService();
  }

  private _recallArcive: any;
  public get recallArchive(): RecallArchiveService {
    return this._recallArcive ??= new RecallArchiveService();
  }

  private _role: any;
  public get role(): RoleService {
    return this._role ??= new RoleService();
  }

  private _receiptLine: any;
  public get receiptLine(): ReceiptLineService {
    return this._receiptLine ??= new ReceiptLineService();
  }

  private _report: any;
  public get report(): ReportService {
    return this._report ??= new ReportService();
  }

  private _scanners: any;
  public get scanners(): ScannersService {
    return this._scanners ??= new ScannersService();
  }

  private _share: any;
  public get share(): ShareService {
    return this._share ??= new ShareService();
  }

  private _service: any;
  public get service(): ServiceService {
    return this._service ??= new ServiceService();
  }

  private _setting: any;
  public get setting(): SettingService {
    return this._setting ??= new SettingService();
  }

  private _storage: any;
  public get storage(): StorageService {
    return this._storage ??= new StorageService();
  }

  private _supplier: any;
  public get supplier(): SupplierService {
    return this._supplier ??= new SupplierService();
  }

  private _supportMessage: any;
  public get supportMessage(): SupportMessageService {
    return this._supportMessage ??= new SupportMessageService();
  }

  private _task: any;
  public get task(): TaskService {
    return this._task ??= new TaskService();
  }

  private _time: any;
  public get time(): TimeService {
    return this._time ??= new TimeService();
  }

  private _treatment: any;
  public get treatment(): TreatmentService {
    return this._treatment ??= new TreatmentService();
  }

  private _userPermissions: any;
  public get userPermissions(): UserPermissionsService {
    return this._userPermissions ??= new UserPermissionsService();
  }

  private _user: any;
  public get user(): UserService {
    return this._user ??= new UserService();
  }

  private _variables: any;
  public get variables(): VariablesService {
    return this._variables ??= new VariablesService();
  }

  private _whatsappService: any;
  public get whatsappService(): WhatsappService {
    return this._whatsappService ??= new WhatsappService();
  }

  public get config(): typeof ConfigService {
    return ConfigService;
  }
}

let _container: ServiceContainer;
export const useServices = () => _container ??= new ServiceContainer();

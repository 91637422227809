export default {
  head: {
    title: "Treatment",
    meta: [
      { name: "description", content: "Description of the treatment page" }
    ]
  },
  general: {},
  treatmentPlan: {
    label: "Treatment plan",
    options: {
      allTreatments: "All treatments",
      estimate: "Estimate",
      plan: "Treatment plan",
    }
  },
  patientNumber: "Patient number",
  doctor: "Doctor",
  doctorPlaceholder: "Choose a doctor",
  status: {
    label: "Status",
    options: {
      estimate: "Estimate",
      active: "Active",
      finished: "Finished"
    }
  },
  tip: {
    clientCard: "Customer card",
    conclusion: "Records",
    xRay: "X-ray",
    photos: "Scans",
    queues: "Appointments",
    invoice: "Invoice",
    financialDetails: "Financial Details",
    remarks: "Remarks",
    history: "History",
    addTreatmentPlan: "Add treatment plan",
    duplicateTreatmentPlan: "Duplicate treatment plan",
    print: "Print",
    send: "Send",
    deleteTreatmentPlan: "Delete treatment plan"
  },
  filterByStatus: {
    label: "Filter by status",
    options: {
      showAll: "Show all",
      estimate: "Estimate",
      inPlanning: "In process",
      finished: "Finished",
    }
  },
  denture: {
    label: "Denture",
    options: {
      fixed: "Fixed",
      removable: "Removable",
      mixed: "Involved",
    }
  },
  view: {
    label: "View",
    options: {
      crossTable: "Chart and table",
      graphView: "Chart view",
      tableView: "Table view",
    }
  },
  treatmentStatus: {
    options: {
      notYetDone: "Not yet done",
      inProgress: "In process",
      canceled: "Canceled",
      approved: "Approved",
      waiting: "Waiting",
      finished: "Finished",
      transferred: "Transferred",
      rejectedByInsuranceCompany: "Rejected by an insurance company"
    }
  },
  removeFilter: "Remove filter",
  newTreatment: "New treatment",
  boxCode: {
    sec: "sec",
  },
  listOfTreatments: "Treatment list",
  selectColumns: "Select Columns",
  columns: {
    id: "#",
    createdAt: "Created at",
    followUp: "Follow-up date",
    title: "Title",
    patientName: "Patient",
    supplierName: "Supplier",
    status: "Status",
  },
  updateTreatment: "Update treatment",
  form: {
    date: "Date",
    startTime: "Start time",
    treatmentDuration: "Duration",
    min: "min",
    treatingDoctor: "Treating doctor",
    status: "Status",
    priceList: "Price list",
    choose: "Choose",
    treatmentCode: "Treatment code",
    sec: "sec",
    treatmentName: "Treatment name",
    frontalOcclusion: "Frontal occlusion",
    teeth: "Teeth",
    surface: "Surface",
    amount: "Quantity",
    unitPrice: "Unit price",
    addTreatment: "Add treatment",
    discount: "Discount",
    totalAmount: "Total amount",
    description: "Description",
    chooseFixedSentence: "Choose fixed sentence",
    add: "Add",
    save: "Save",
    makeAppointment: "Make appointment",
    viewAppointment: "View appointment",
    reallyRemove: "Do you want to delete this treatment?",
  },
  modal: {
    findings: {
      title: "Records",
      addNew: "Add new record",
      table: {
        columns: {
          createdAt: "Date",
          description: "Description"
        }
      }
    },
    photos: {
      title: "Scans",
      totalPhotos: "Total {0} photos",
      openFiles: "Open files",
    },
    queues: {
      title: "Appointments",
      newQueue: "New appointment",
      table: {
        columns: {
          startTime: "Start time",
          doctorName: "Doctor",
          serviceName: "Service",
          note: "Note",
        }
      },
    },
    financialDetails: {
      title: "Treatment details",
      totalPlan: "Price",
      materialsCost: "Materials cost",
      hourlyCost: "Hours cost",
      discount: "Discount",
      profit: "Profit",
      ended: "Finished",
      paid: "Paid",
      unpaid: "Unpaid",
      programDiscount: {
        title: "Program discount",
        desc: "Please choose how to set the discount",
        percentage: "Percentage",
        amount: "Amount",
        askingPrice: "Set price",
      },
      cancel: "Cancel",
      save: "Save",
    },
    notes: {
      title: "Remarks",
    },
    history: {
      title: "History",
      table: {
        columns: {
          createdAt: "Date",
          userName: "User",
          action: "Action",
          entity: "Description",
        }
      },
      action: {
        CREATE: "{entityName} #{entityId}",
        UPDATE: "{entityName} #{entityId} {field}: {valueOld} 🡆 {valueNew}",
        DELETE: "{entityName} #{entityId}",
      }
    },
    send: {
      notification: "The treatment plan has been sent successfully",
      title: "Send treatment plan",
      subject: "Subject",
      message: "Message",
      validation: {
        subject: "Subject is required",
        message: "Message is required",
      }
    }
  }
}

export default {
  titles: {
    edit: "עריכת ממצאים",
    create: "הוספת ממצאים"
  },
  doctor: "רופא",
  type: "סוג",
  types: {
    regular: "רגיל",
    bold: "מודגש"
  },
  description: "תיאור",
  submit: "שמור"
}

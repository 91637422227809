export default {
  head: {
    title: "שירותים",
    meta: [
      { name: "description", content: "שירותים" }
    ]
  },
  title: "שירותים",
  token: "אסימון",
  category: "קטגוריית תבנית טיפול",
  name: "שם תבנית טיפול",
  emailSubject: 'כותרת (דוא"ל בלבד)',
  code: "קוד טיפול",
  description: "תיאור",
  appointmentMinutes: "משך תור בדקות",
  materialPrice: "מחיר החומר בשקלים",
  recallMessageAutomatically: "שליחת הודעת ריקול אוטומטית",
  recallDays: "ימי ריקול",
  recallTemplate: "תבנית ריקול",
  message: "הודעה",
  allowWebpageBooking: "ניתן להזמין באינטרנט",
  active: "השירות פעיל",
  selectOption: "יש לבחור באפשרות הרצויה",
  sendWithWhatsapp: "לשלוח דרך וואטסאפ",
  sendWithSms: "לשלוח דרך סמס",
  sendWithEmail: 'לשלוח דרך דוא"ל',
  notesInReminder: "הוראות לפני טיפול",
  notesInFollowUp: "הוראות לאחר טיפול",
  continue: "הבאה",
  createService: "הוספת תבנית טיפול",
  updateService: "עדכן שירות מס' {0}",
  validation: {
    exists: "טיפול קיים בבסיס הנתונים.",
  }
}

export default {
  title: "Medical forms",
  table: {
    columns: {
      id: "ID",
      createdAt: "Create date",
      status: "Status",
      doctorName: "Doctor",
      templateTitle: "Type",
      description: "Notes",
    }
  },
  edit: {
    title: "Medical form",
    templateId: "Form type",
    description: "Content",
    saveAndSend: "Save and send",
    save: "Save",
  },
  popup: {
    title: "Sending a form to the patient",
    subject: "Subject",
    message: "Message",
    linkMessage: "A link to the form will be automatically attached at the bottom of the message.",
    notification: "Message for medical form sent successfully",
    validation: {
      subject: "Subject is required",
      message: "Message is required",
    }
  }
}

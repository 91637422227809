<template>
  <Dialog
    v-if="visible"
    modal
    v-model:visible="visible"
    :header="_vm.header?.text"
    :show-header="_vm.header?.show"
    :style="{ width: `${_vm.dialogWidth ?? 550}px` }"
    v-on:update:visible="systemHide">
    <template v-if="_vm.header?.html" #header> <div v-html="headerHtml"></div> </template>
    <div class="prime-popup-content" :style="{ height: `${_vm.contentHeight ?? 275}px` }">
      <template v-if="_vm.type === 'alert'">
        <div class="row h-50">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <i :class="alertIconClass"></i> <span class="h5 mt-2 text-center">{{ _vm.alert?.msgText }}</span>
          </div>
        </div>
        <div class="row h-50">
          <div class="col-12 d-flex justify-content-center mt-auto">
            <button class="btn btn-primary w-25" @click="hide()">{{ _vm.alert?.btnText ?? t("default.close") }}</button>
          </div>
        </div>
      </template>
      <template v-else-if="_vm.type === 'confirm'">
        <div class="container-fluid d-flex flex-column h-100">
          <!--icons-->
          <div class="row">
            <div class="col-12">
              <div v-if="_vm.confirm?.icon == 'question'" class="swal2-icon swal2-question swal2-icon-show confirm-icon-from-swal">
                <div class="swal2-icon-content">?</div>
              </div>
              <div v-else-if="_vm.confirm?.icon == 'warning'" class="swal2-icon swal2-warning swal2-icon-show confirm-icon-from-swal" style="scale: 0.75">
                <div class="swal2-icon-content">!</div>
              </div>
              <div v-else-if="_vm.confirm?.icon == 'info'" class="swal2-icon swal2-info swal2-icon-show confirm-icon-from-swal">
                <div class="swal2-icon-content">i</div>
              </div>
              <div v-else-if="_vm.confirm?.icon == 'error'" class="swal2-icon swal2-error swal2-icon-show confirm-icon-from-swal">
                <span class="swal2-x-mark">
                  <span class="swal2-x-mark-line-left"></span>
                  <span class="swal2-x-mark-line-right"></span>
                </span>
              </div>
              <div v-else-if="_vm.confirm?.icon == 'success'" class="swal2-icon swal2-success swal2-icon-show confirm-icon-from-swal">
                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255)"></div>
                <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255)"></div>
                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255)"></div>
              </div>
              <div v-else class="h4 text-danger text-center">{{ t("default.error") }}</div>
            </div>
          </div>
          <!--text-->
          <div class="row flex-grow-1">
            <div class="col-12 d-flex justify-content-center align-items-center">
              <span class="text-center font-size-18">{{ _vm.confirm?.message }}</span>
            </div>
          </div>
          <!--buttons-->
          <div class="row">
            <div class="col-6">
              <button class="btn btn-primary w-100" @click="confirmSubmit()">{{ _vm.confirm?.btnYes ?? t("default.yes") }}</button>
            </div>
            <div class="col-6">
              <button class="btn btn-secondary w-100" @click="confirmCancel()">{{ _vm.confirm?.btnNo ?? t("default.no") }}</button>
            </div>
          </div>
        </div>
      </template>
      <FormKit v-else-if="_vm.type === 'prompt'" type="form" :actions="false" @submit="promptSubmit()">
        <div class="form-row row h-75">
          <div class="form-group col-12">
            <FormKit
              type="text"
              v-model="promptModel"
              validation-visibility="live"
              :validation="promptValidation"
              :validation-messages="promptValidationMessages"
              :input-class="`form-control w-100 ${_vm.prompt?.textInputClass ?? ''}`" />
          </div>
        </div>
        <div class="row h-25">
          <div class="col-6">
            <button class="btn btn-primary w-100" type="submit">{{ _vm.prompt?.btnSubmit ?? t("default.ok") }}</button>
          </div>
          <div class="col-6">
            <button class="btn btn-secondary w-100" type="button" @click="hide()">{{ _vm.prompt?.btnCancel ?? t("default.cancel") }}</button>
          </div>
        </div>
      </FormKit>
      <template v-else>
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <span class="h5 text-warning mt-2">Undefined type, </span> <strong class="text-success mt-2">allowed types: ("alert", "confirm", "prompt")</strong>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <button class="btn btn-primary mt-2" @click="hide()">{{ t("default.close") }}</button>
          </div>
        </div>
      </template>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
  import Dialog from "primevue/dialog";
  import { ref, reactive, computed } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStores } from "@/stores";
  import { AutoMapper } from "@/utils";
  import type { PopupOptions, ValidationMessages } from "@/models/interfaces";

  const { t } = useI18n();
  const stores = useStores();

  const _u = undefined;

  const _vm = reactive<PopupOptions>({
    type: _u,
    dialogWidth: _u,
    contentHeight: _u,
    header: _u,
    alert: _u,
    confirm: _u,
    prompt: _u
  });

  const visible = ref(false);
  const headerHtml = ref<string>();
  const promptModel = ref<string>();
  const promptValidation = ref<string | Array<[rule: string, ...args: any]>>();
  const promptValidationMessages = ref<ValidationMessages>();

  const alertIconClass = computed(() => _vm.alert?.iconClass ?? "mdi mdi-alert-circle-outline fs-3 text-warning me-2");

  function show(options: PopupOptions) {
    init(options);
    visible.value = true;
  }

  function _hide() {
    if (_vm.type == "alert" && _vm.alert?.onClose) {
      _vm.alert?.onClose();
    }
    clear();
    stores.popup.hidePopup();
  }

  function hide() {
    visible.value = false;
    _hide();
  }

  function systemHide(visible: boolean) {
    if (!visible) {
      _hide();
    }
  }

  function init(options: PopupOptions) {
    AutoMapper.map(options, _vm);

    if (_vm.header?.html) {
      if (_vm.header.html instanceof HTMLElement) headerHtml.value = _vm.header.html.outerHTML;
      else if (typeof _vm.header.html === "string") headerHtml.value = _vm.header.html;
    }

    if (_vm.prompt?.model) {
      promptModel.value = _vm.prompt.model;
    }
    if (_vm.prompt?.validation) {
      promptValidation.value = _vm.prompt.validation;
    }
    if (_vm.prompt?.validationMessages) {
      promptValidationMessages.value = _vm.prompt?.validationMessages;
    }
  }

  function clear() {
    _vm.type = _u;
    _vm.header = _u;
    _vm.contentHeight = _u;
    _vm.dialogWidth = _u;
    _vm.alert = _u;
    _vm.confirm = _u;
    _vm.prompt = _u;

    headerHtml.value = _u;
    promptValidation.value = _u;
    promptModel.value = _u;
    promptValidationMessages.value = _u;
  }

  function promptSubmit() {
    if (_vm.type == "prompt" && _vm.prompt?.onSubmit) {
      _vm.prompt.onSubmit(promptModel.value);
    }
    hide();
  }

  function confirmSubmit() {
    if (_vm.type == "confirm" && _vm.confirm?.onConfirm) {
      _vm.confirm.onConfirm();
    }
    hide();
  }

  function confirmCancel() {
    if (_vm.type == "confirm" && _vm.confirm?.onCancel) {
      _vm.confirm?.onCancel();
    }
    hide();
  }

  defineExpose({
    show
  });
</script>

<style scoped lang="scss">
  .confirm-icon-from-swal {
    display: flex;
    margin: auto !important;
  }
</style>

export default {
  head: {
    title: "Service",
    meta: [
      { name: "description", content: "Service" }
    ]
  },
  title: "Service",
  token: "Token",
  category: "Service category",
  name: "Service name",
  emailSubject: 'Email subject',
  code: "Service code",
  description: "Description",
  appointmentMinutes: "Number of appointment minutes",
  materialPrice: "Material price",
  recallMessageAutomatically: "Allow sending an automatic recall message",
  recallDays: "Service recall days",
  recallTemplate: "Service recall template",
  message: "Message",
  allowWebpageBooking: "Allow to book on webpage",
  active: "Service is active",
  selectOption: "Select option",
  sendWithWhatsapp: "Send with Whatsapp",
  sendWithSms: "Send with SMS",
  sendWithEmail: "Send with Email",
  notesInReminder: "Instructions before treatment",
  notesInFollowUp: "Instructions after treatment",
  continue: "Continue",
  createService: "Create service",
  updateService: "Update service",
  validation: {
    exists: "Service exist in database.",
  }
}

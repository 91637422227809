export default {
  actions: {
    edit: "Edit",
    archive: "Archive",
    unarchive: "Unarchive"
  },
  head: {
    title: "Laboratory works",
    meta: [
      { name: "description", content: "Laboratory works description" }
    ]
  },
  table: {
    columns: {
      id: "#",
      createdAt: "Created",
      followUp: "Follow up",
      title: "Task",
      patientName: "Patient",
      laboratoryName: "Laboratory",
      status: "Status"
    }
  },
  searchPlaceholder: "Search",
  filter: {
    status: "Status",
    all: "All",
    new: "New",
    closed: "Closed",
    created: "Created",
    followUp: "Follow up"
  },
  status: {
    NEW: "New",
    CLOSED: "Closed"
  },
  download: "Download",
  newLaboratoryWork: "New laboratory work",
  labs: "Laboratories"
};

export default {
  head: {
    title: "עבודות מעבדה",
    meta: [
      { name: "description", content: "תיאור עבודות מעבדה" }
    ]
  },
  notesTable: {
    columns: {
      id: "#",
      createdAt: "נוצר ב",
      text: "הערות"
    }
  },
  addFile: "הוספת קובץ",
  saveLaboratoryWorkBeforeUpload: "נא לשמור עבודות מעבדה לפני העלאת קבצים מצורפים",
  addNote: "הוסף הערה",
  addNotes: "הוסף הערה",
  addedFile: "נוסף קובץ",
  attachmentSaved: "קובץ נוסף",
  created: "נוצרה ב",
  followUp: "תאריך מעקב",
  history: "הסטוריה",
  laboratoryWorkSaved: "עבודת מעבדה נשמרה",
  newLaboratoryWork: "הוספת עבודת מעבדה",
  noteSaved: "הערה נשמרה",
  notes: "הערות",
  patient: "מטופלים",
  status: "סטטוס",
  laboratory: "מעבדה",
  title: "כותרת"
};

export default {
  head: {
    title: "Business settings",
    meta: [
      { name: "description", content: "Business settings description" }
    ]
  },
  title: "Business settings",
  actions: {
    submit: "Continue",
    change: "Change"
  },
  validation: {
    imgNotSupported: "This file is not supported",
    imgAlreadyRemoved: "Image not exist or already removed",
    pdfAlreadyRemoved: "Pdf file not exist or already removed"
  },
  popups: {
    rmFileQuestion: "Remove file?"
  },
  tabs: {
    details: {
      title: "Details",
      businessName: "Business name",
      businessNumber: "Business ID (H.P.)",
      businessLogo: "Logo",
      businessSignature: "Signature",
      businessType: "Business type",
      businessTypeOptions: {
        COMPANY: "Company",
        INDIVIDUAL_BUSINESS: "Individual business",
        SMALL_BUSINESS: "Small business"
      }
    },
    address: {
      title: "Address",
      address: "Address",
      city: "City",
      zip: "Zip code",
      mobilePhone: "Mobile phone",
      landPhone: "Land phone",
      fax: "Fax",
      email: "Email",
      site: "Site"
    },
    bank: {
      title: "Bank",
      helpText: "Here you fill in all the account details the business's bank for making transactions and receiving payments",
      bankBeneficiary: "Beneficiary name",
      bankName: "Bank name and code",
      bankBranch: "Branch number",
      bankAccount: "Account number",
      bankSwift: "SWIFT code",
      bankAba: "ABA code",
      bankIban: "IBAN account number",
      displayBank: "Display this bank account details in documents sent to customers"
    },
    accounting: {
      title: "Accounting",
      taxDeduction: {
        title: "Certificates for tax deduction",
        text: "To produce the certificates on the website of the Tax Authority",
        link: "Click here"
      },
      taxDetails: "Business details for taxation purposes",
      bookkeeping: "Bookkeeping permit",
      withholdingTax: "Withholding tax permit",
      vatType: "VAT type",
      vatTypeOptions: {
        NO_VAT: "No vat",
        MONTHLY: "Monthly",
        BI_MONTHLY: "Bi monthly"
      },
      incomeTaxRate: "Income tax advances (in percentages)",
      nationalInsuranceRate: "Advances to the national insurance (in percentages)",
      vatDeductionRate: "Withholding tax rate (in percentages)",
      taxFileNumber: "Deductions file number",
      method: "Income reporting method",
      methodOptions: {
        CASH_BASED: "Cash based",
        INVOICE_BASED: "Invocie based"
      },
      recallInclude: "Include recall in forecast",
      recallPercent: "Recall percent",
      emptyFile: "File not exist"
    },
    documentContent: {
      title: "Document content",
      helpText: "Here you can give a unique character to your documents and define a fixed text to be attached to them",
      documentEstimate: "Estimate",
      documentProformaInvoice: "Proforma invoice",
      documentInvoice: "Invoice",
      documentInvoiceReceipt: "Invoice receipt",
      documentReceipt: "Receipt",
      documentReceiptRefound: "Receipt refound",
      documentInvoiceRefound: "Invoice refound"
    },
    documentNumbers: {
      title: "Document numbers",
      text: "Financial documents must be produced according to a consistent numerical sequence. Here, you can set the sequence for each document type. The number specified in the field will determine the number of the next document to be produced."
    },
    documentSettings: {
      title: "Document settings",
      helpText: "Here you can define some data that will make it easier for you to produce documents",
      vatInServices: "VAT in details of services and items",
      defaultCentsRounding: "Automatic cents rounding",
      emailSubject: {
        legend: "The subject of the email",
        labelProformaInvoiceNumber: "Proforma invoice number - Business name",
        labelProformaInvoice: "Proforma invoice - Business name (without specifying the document number)",
        labelDocumentDescription: "Document description - Business name"
      },
      notifications: {
        legend: "How do you want us to inform you about sending the document?",
        documentManualCreation: "After producing a document on the website or in the application, email me the original document",
        documentAfterApiCreation: "After producing a document in the API, after the customer has paid by credit card, send me the original document by email",
        emailOnBounce: "If the distribution of the document by email to the customer failed, send me a message updating",
        emailOnError: "If an error occurred when generating a document for transactions with external interfaces (credit clearing, etc.), send me an update message"
      }
    },
    bookkeeping: {
      title: "Bookkeeping",
      emailLegend: "Emails list for accounting (divided by comma)",
      sendReport: {
        legend: "What reports should be sent automatically?",
        expenses: "Incomes",
        incomes: "Expenses"
      },
      day: {
        legend: "Sending day",
        optionNum2: "on the 2nd of the month",
        optionNum5: "on the 5nd of the month",
        optionNum9: "on the 9nd of the month"
      },
      frequency: {
        legend: "Sending frequency",
        labelMonthly: "Every month",
        labelBiMonthly: "Every two months"
      },
      sends: {
        documents: "Send a copy of every financial document generated in the system to accounting"
      }
    },
    calendar: {
      title: "Calendar",
      businessWorkDays: "Default working days (in calendar settings)",
      businessHoursStart: "Default business hours from",
      businessHoursEnd: "Default business hours to",
      weekStartDay: "Default week start day",
      timeZoneId: "Default time zone",
      defaultDoctor: "Default doctor",
      eventColor: "Default event color",
      haveAutoAttendanceLog: "Activate automatic attendance log when user logged in",
      durationValues: "Appointment duration (in minutes, 1 value per line)",
      addAppointmentMinutes: "Add appointment on right click (in minutes, 1 value per line)",
      durationIntervalMinutes: "Default appointment duration (in minutes)",
      dayOptions: {
        sunday: "Sun",
        monday: "Mon",
        tuesday: "Tue",
        wednesday: "Wed",
        thursday: "Thu",
        friday: "Fri",
        saturday: "Sat"
      },
    },
    treatment: {
      title: "Treatment",
      hourPrice: "Treatment hour price (for estimate)",
      fixedNotes: "Treatment notes (1 value per line)",
      medicalRengens: "Medical scanners JSON"
    }
  }
}

export default {
  head: {
    title: "Laboratory work",
    meta: [
      { name: "description", content: "Laboratory work description" }
    ]
  },
  notesTable: {
    columns: {
      id: "ID",
      createdAt: "Created",
      text: "Note"
    }
  },
  addFile: "Add File",
  saveLaboratoryWorkBeforeUpload: "Please save laboratory work before uploading attachments",
  addNote: "Add Note",
  addNotes: "Add Notes",
  addedFile: "Added file",
  attachmentSaved: "Attachment saved",
  created: "Created",
  followUp: "Follow up",
  history: "History",
  laboratoryWorkSaved: "Laboratory work saved",
  newLaboratoryWork: "New laboratory work",
  noteSaved: "Note saved",
  notes: "Notes",
  patient: "Patient",
  status: "Status",
  laboratory: "Laboratory",
  title: "Title"
};

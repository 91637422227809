import GraphqlService from "../GraphqlService";
import type { MessageProvider } from "@/models/enums";

export default class ShareService {
  async sendSelfRegister(destination: string, addtionalText: string, provider: MessageProvider) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendSelfRegister",
      variables: [
        { field: "destination", value: destination, valueType: "String" },
        { field: "additionalText", value: addtionalText, valueType: "String" },
        { field: "provider", value: provider, valueType: "MessageProvider!" }
      ],
    });
    return data ?? false;
  }
}

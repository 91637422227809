import GraphqlService from "../GraphqlService";
import type JwtToken from "@/models/api/identity/JwtToken";
import type { Result } from "@/models/interfaces/Result";

export default class ApiAuthService {
  public get isSignedIn(): boolean {
    if (!this.token.access) {
      return false;
    }

    this.refreshTokenTask();

    return Date.parse(this.token.expires) > Date.now();
  }

  public get token(): JwtToken {
    const token = localStorage.getItem("token");
    return token ? JSON.parse(token) : {
      expires: new Date().toISOString()
    };
  }

  private async getToken(userName: string, password: string, dbName: string) {
    const { data } = await GraphqlService.queryGql<Result<JwtToken>>({
      method: "token",
      fields: ["data { access refresh expires }", "success", "message"],
      variables: [
        { field: "userName", value: userName, valueType: "String!" },
        { field: "password", value: password, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return data;
  }

  private async refreshToken() {
    const { data } = await GraphqlService.queryGql<JwtToken>({
      method: "refreshToken",
      fields: ["access", "refresh", "expires"],
      variables: [
        { field: "access", value: this.token.access, valueType: "String!" },
        { field: "refresh", value: this.token.refresh, valueType: "String!" },
      ]
    });
    return data;
  }

  public async signIn(userName: string, password: string, dbName: string) {
    const result = await this.getToken(userName, password, dbName);

    if (result && result.success && result.data) {
      localStorage.setItem("token", JSON.stringify({ access: result.data.access, refresh: result.data.refresh, expires: result.data.expires }));
      this.refreshTokenTask(true);
    }

    return result;
  }

  public signOut() {
    localStorage.removeItem("token");
  }

  private _refreshTokenHandle: number | null = null;
  private refreshTokenTask(force?: boolean) {
    if (this._refreshTokenHandle && !force) return;
    if (!this.token.refresh) return;

    const timeout = (Date.parse(this.token.expires) - Date.now()) / 2;
    this._refreshTokenHandle = window.setTimeout(async () => {
      const token = await this.refreshToken();
      if (token) {
        localStorage.setItem("token", JSON.stringify({ access: token.access, refresh: token.refresh, expires: token.expires }));
        this.refreshTokenTask(true);
      }
    }, timeout);
  }

  //#region password
  public async resetPassword(email: string, dbName: string) {
    const { data, error } = await GraphqlService.queryGql<boolean>({
      method: "resetPassword",
      variables: [
        { field: "email", value: email, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return data ?? false;
  }

  public async changePassword(email: string, resetToken: string, passwordNew: string, dbName: string) {
    const { data, error, message } = await GraphqlService.queryGql<boolean>({
      method: "changePassword",
      variables: [
        { field: "email", value: email, valueType: "String!" },
        { field: "resetToken", value: resetToken, valueType: "String!" },
        { field: "passwordNew", value: passwordNew, valueType: "String!" },
      ],
      context: { headers: { "X-DBNAME": dbName } }
    });
    return { success: data ?? false, error: message };
  }
  //#endregion
}

import { defineStore } from "pinia";
import { useServices } from "@/services";
import { StorageFileType } from "@/models/enums";
import type { SettingSchema } from "@/models/schemas";
import GraphqlService from "@/services/GraphqlService";
import { ArrayHelper } from "@/utils";

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useSettingsStore = defineStore({
  id: 'settings',
  state: () => ({
    settings: [] as SettingSchema[],
    subscription: null as any,
  }),
  actions: {
    setAll(settings: SettingSchema[]) {
      this.settings = settings;
    },
    async subscribe() {
      this.subscription = await GraphqlService.subscribeSchema("SettingSchema", {
        onResult: async (data) => {
          const settings = await useServices().setting.getSettingsAll([{ field: "id", type: "in", value: data.ids, valueType: "[Int!]" }]);
          settings.forEach(setting => ArrayHelper.replace(this.settings, x => x.id === setting.id, setting));
          //console.log(this.settings.filter(x => data.ids.includes(x.id)));
        },
        onError: (error) => {
          console.log("[settings] subscribe", error);
          this.subscription = null;
          setTimeout(() => this.subscribe(), 3000);
        }
      });
    },
    get(key: string): SettingSchema | null {
      return this.settings.find(x => x.key === key) ?? null;
    },
    getValue<T = string>(key: string, defaultValue: T) {
      const setting = this.get(key);
      if (setting) return useServices().setting.parseSetting<T>(setting) ?? defaultValue;
      return defaultValue;
    },
    getCompany() {
      const logo = this.getValue<string>("BusinessLogo", "");
      const logoLink = logo ? useServices().storage.getLink(StorageFileType.None, logo) : null;

      return {
        name: this.getValue<string>("BusinessName", ""),
        number: this.getValue<string>("BusinessNumber", ""),
        address: this.getValue<string>("BusinessAddress", ""),
        zip: this.getValue<string>("BusinessZip", ""),
        landPhone: this.getValue<string>("BusinessLandPhone", ""),
        mobilePhone: this.getValue<string>("BusinessMobilePhone", ""),
        url: this.getValue<string>("BusinessWebsite", ""),
        email: this.getValue<string>("BusinessEmail", ""),
        logoLink: logoLink,
      }
    }
  }
})

export default {
  head: {
    title: "מטופלים",
    meta: [
      { name: "description", content: "מטופלים" }
    ]
  },
  table: {
    columns: {
      id: "#",
      fileNumber: "מספר תיק",
      passportNumber: "מספר ת\"ז",
      firstName: "שם מטופל",
      phone1: "טלפון",
      email1: "דוא״ל",
      defaultDoctorName: "רופא מטפל",
      lastAppointmentTime: "ביקור אחרון",
      nextAppointmentTime: "ביקור הבא",
      balance: "יתרה",
      lastVisitDate: "תאריך ביקוק אחרון"
    }
  },
  actions: {
    send: "שליחת הודעה",
    sendAppointment: "שליחת קישור לזימון תור",
    edit: "עריכה",
    medicalEdit: "עריכה רפואית",
    archive: "העברה לארכיון",
    unarchive: "העבר לפעילים",
    download: "הורדת אקסל",
    mobileReception: "קליטה מהנייד",
    newPatient: "מטופל חדש"
  },
  searchPlaceholder: "חיפוש",
  filter: {
    all: "הכל",
    active: "פעיל",
    inactive: "ארכיון",
    lastAppointment: "ביקור אחרון",
    nextAppointment: "ביקור הבא",
    doctor: "רופא מטפל",
    haveDebt: "חוב",
  },
  reallyDeactivate: "אתה באמת רוצה בטל הפעלה {0}?",
  deactivate: "בטל הפעלה"
}

import GraphqlService from "../GraphqlService";
import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";
import type { NotificationSchema } from "@/models/schemas";

export default class NotificationService {
  async getCurrentNotifications() {
    const { client } = useApolloClient();
    const { data } = await client.query<{ notifications: QueryOffsetPage<NotificationSchema> }>({
      query: gql`
        query currentNotifications {
          notifications(
            take: 100
          )
          {
            totalCount
            items {
              id
              createdAt
              titleJson,
              contentJson,
              type,
              link
            }
          }
        }`,
      variables: {}
    });
    return data;
  }

  async closeNotification(id: number) {
    return await GraphqlService.deleteItem("closeNotification", id);
  }
}
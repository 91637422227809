﻿export default {
  head: {
    title: "הגדרות Whatsapp",
    meta: [
      { name: "description", content: "הגדרות Whatsapp" }
    ]
  },
  cantGetSessionStatus: 'לא ניתן לקבל את מצב החיבור שלך. אנא נסה שוב מאוחר יותר.',
  checkingConnectionStatus: "בודק חיבור לשרת ...",
  connected: 'מחובר',
  loggingOut: 'מתנתק מהשרת ...',
  logout: 'להתנתק',
  warningLine1: 'המערכת מציעה שתי אפשרויות חיבור.',
  warningLine2: 'אפשרות ראשונה התחברות על ידי סריקת הקוד QR של ווטסאפ, אשר תאפשר לנהל צאט בווטסאפ, שליחת זימוני תורים, שליחת מסמכים, טפסים להחתמה ועוד.',
  warningLine3: 'עם זאת, יש לציין, כי אופציה זו עשויה להפסיק לפעול באופן בלתי צפוי ולזמן קצר משום שאנו תלויים בשרות של ווטסאפ העולמית.',
  warningLine4: 'לכן, איננו יכולים להבטיח את פעולתו הרציפה של השרות הזה.',
  instructionHeader: 'כיצד להשתמש בווטסאפ בתוכנה',
  instructionLine1: 'פותחים את ווטסאפ בטלפון.',
  instructionLine2Part1: 'מקישים על תפריט',
  instructionLine2Part2: 'או הגדרות',
  instructionLine2Part3: ' ב אייפון',
  instructionLine3: 'מקישים על מכשירים משקושרים ואז על קישור מכשיר',
  instructionLine4: 'מפנים את הטלפון למסך כדי לצלם את קוד ה QR',

  sms: {
    head: {
      title: "הגדרות סמס",
      meta: [
        { name: "description", content: "הגדרות סמס" }
      ]
    },
    haveMessages: ["נשארו לכם {0} הודעות סמס בחבילה שרכשתם. להגדלת החבילה", "לחצו כאן"],
  }
}

export default {
  appointmentStatus: {
    NONE: "None",
    NEW: "New",
    CANCELED: "Canceled",
    REMINDER_SENT: "Reminder sent",
    APPROVED: "Approved",
    TOOK_PLACE: "Took place",
    DID_NOT_COME: "Did not come",
    deleted: "Deleted",
  },
  taskStatus: {
    NONE: "None",
    NEW: "New",
    CLOSED: "Closed",
  },
  incomeType: {
    NONE: "None",
    ESTIMATE: "Estimate",
    PROFORMA_INVOICE: "Proforma invoice",
    INVOICE: "Invoice",
    INVOICE_RECEIPT: "Invoice receipt",
    RECEIPT: "Receipt",
    INVOICE_REFOUND: "Invoice refound",
    RECEIPT_REFOUND: "Receipt refound"
  },
  incomeStatus: {
    NONE: "None",
    OPEN: "Open",
    CLOSED: "Closed",
    INVOICED: "Invoiced",
    MANUALLY_CLOSED: "Manually closed"
  },
  medicalFormStatus: {
    NONE: "None",
    SENT: "Sent",
    VIEWED: "Viewed",
    FILLED_UP: "Filled up",
  },
  receiptPaymentType: {
    NONE: "None",
    CHEQUE: "Cheque",
    BANK_TRANSFER: "Bank transfer",
    CREDIT_CARD: "Credit card",
    CASH: "Cash",
    WITH_HOLDING_TAX: "With holding tax"
  },
  creditDealType: {
    NONE: "None",
    REGULAR: "Regular",
    PAYMENTS: "Payments",
    CREDIT: "Credit",
    CHARGE_DECLINED: "Charge declined",
    OTHER: "Other"
  },
  creditBrand: {
    NONE: "None",
    ISRACARD: "Isracard",
    VISA: "Visa",
    MASTER_CARD: "Master card",
    AMERICAN_EXPRESS: "American express",
    DINERS: "Diners"
  },
  treatmentStageStatus: {
    NONE: "None",
    ESTIMATE: "Estimate",
    ACTIVE: "Active",
    FINISHED: "Finished"
  },
  frequency: {
    NONE: "None",
    ONE_TIME: "One time",
    WEEKLY: "Weekly",
    BI_WEEKLY: "Every bi week",
    MONTHLY: "Monthly",
    BI_MONTHLY: "Every bi month"
  },
  dayOfWeek: {
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday"
  },
  evenOrOdd: {
    NONE: "None",
    EVEN: "Even",
    ODD: "Odd"
  },
  historyAction: {
    NONE: "None",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
  },
  vatType: {
    NONE: "None",
    NORMAL: "Normal",
    NO_VAT: "No vat"
  },
  vatBeforeOrInclude: {
    NONE: "None",
    BEFORE_VAT: "Before",
    INCLUDE_VAT: "Included"
  },
  messageProvider: {
    NONE: "None",
    WHATSAPP: "Whatsapp",
    SMS: "Sms",
    EMAIL: "Email"
  },
  messageTemplateType: {
    NONE: "None",
    GENERAL: "General",
    PATIENT_APPOINTMENT_CREATED: "Patient appointment created",
    PATIENT_APPOINTMENT_REMINDER: "Patient appointment reminder",
    PATIENT_APPOINTMENT_CANCELED: "Patient appointment canceled",
    SECRETARY_NEW_LEAD: "Secretary new lead",
    ACCOUNTANT_INCOMES_REPORT: "Accountant incomes report",
    ACCOUNTANT_EXPENSES_REPORT: "Accountant expenses report",
    LABORATORY_NEW_LABORATORY_WORK: "Laboratory new laboratory work",
    NEW_TASK: "New task",
    SEND_MEDICAL_FORM_TEMPLATE: "Send medical form template",
    SEND_APPOINTMENT_LINK_TEMPLATE: "Send appointment link template",
    SEND_FINANCIAL_DOCUMENT: "Send financial document",
    NOTES_IN_FOLLOW_UP: "Notes in follow up",
    SIGN_MEDICAL_FORM_REMINDER: "Sign medical form reminder",
    SEND_TREATMENT_PLAN_TEMPLATE: "Send treatment plan template"
  }
}

import type { LeadStatusSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  name
`;

export default class LeadStatusService {
  async getLeadStatuses(limit: number, page: number) {
    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [],
      sort: [{ field: "id", dir: "asc" }]
    };
    return await GraphqlService.getItems<LeadStatusSchema>("leadStatuses", fields, params);
  }

  async getLeadStatusesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<LeadStatusSchema[]>({
      method: "leadStatusesAll",
      fields: fields ?? ["id", "name"],
      order: [{ field: "name", value: "ASC" }]
    });
    return data ?? [];
  }

  async getLeadStatus(id: number) {
    return await GraphqlService.getItem<LeadStatusSchema>("leadStatus", fields, id);
  }
}

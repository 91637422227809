export default {
  title: "קבצים",
  createNew: "ליצור חדש",
  folder: "תיקיה",
  file: "קובץ",
  cameraPhoto: "צילם ממצלמה",
  rootFolder: "ראשי",
  search: "חיפוש לפי שם...",
  open: "לפתוח קובץ",
  rename: "לשנות שם",
  remove: "למחוק",
  table: {
    columns: {
      name: "שם",
      dateModified: "תאריך יצירה",
      size: "גודל",
    }
  },
  writeFolderName: "בבקשה לרשום שם תיקיה",
  errorFolderName: "שם תיקיה לא תקים",
  writeNewName: "בבקשה לרשום שם קובץ",
  errorNewName: "שם קובץ לא תקים",
  camera: {
    title: "צילום תמונה",
  }
}

export default {
  head: {
    title: "Attendance",
    meta: [
      { name: "description", content: "Attendance description" }
    ]
  },
  automaticTime: "Automatic time",
  addAttendance: "Add Attendance",
  addAttendanceFormHeader: "Add Hours report",
  updateAttendance: "Update work time",
  chooseUser: "Select user",
  manualTime: "Manual time",
  example: "example",
  member: "Member",
  fromHour: "From hour",
  toHour: "To hour",
  note: "Note",
  date: "Date",
  fromDate: "Date from",
  toDate: "Date to",
  deletePopupText: "Are you sure you want to delete this attendance?",
  fromDateValidationMessage: "The date from must be earlier than the date to",
  toDateValidationMessage: "The Date to must be after the Date from",
  attendanceReport: "Attendance Report",
  reportDownload: "Download",
  totalWorked: "Total worked",
  "title": "Attendance",
  "continue": "Continue",
  "delete_attendance": "Delete Attendance",
}

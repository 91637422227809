export default {
  head: {
    title: "לידים",
    meta: [{ name: "description", content: "רשימת פניות" }]
  },
  edit: {
    followUp: "תאריך מעקב",
    status: "סטטוס",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    phone: "טלפון",
    email: "דוא\"ל",
    service: "שירות",
    channel: "ערוץ",
    otherData: "נתונים נוספים",
    note: "הערות",
  },
  notesTable: {
    columns: {
      id: "#",
      createdAt: "נוצר ב",
      text: "הערה"
    }
  },
  addFile: "הוספת קובץ",
  addNote: "הוספת הערה",
  addNotes: "הוספת הערות",
  addedFile: "הוספת קובץ",
  assignee: "משוייך ל",
  attachmentSaved: "קובץ נשמר",
  created: "נוצר ב",
  followUp: "תאריך מעקב",
  history: "הסטוריה",
  newLead: "לקוח פוטנציאלי חדש",
  noteSaved: "הערה נשמרה",
  notes: "הערות",
  patient: "מטופל",
  saveLeadBeforeUpload: "בבקשה לשמור לפני העלאת קובץ",
  status: "סטטוס",
  save: "שמירה",
  leadSaved: "ליד נשמר",
  emailOrPhoneValidation: 'דוא"ל או טלפון חובה'
};
